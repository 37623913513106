import { useStyletron } from '@tigergraph/app-ui-lib/Theme';
import { SVGProps } from 'react';

export const AccessBlocked = (props: SVGProps<SVGSVGElement>) => {
  const [, theme] = useStyletron();
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={16} height={16} fill="none" {...props}>
      <path
        fill={theme.colors['modal.icon']}
        fillRule="evenodd"
        d="M2.793 2.98 7.46.907c.34-.154.74-.154 1.08 0l4.667 2.073c.48.213.793.693.793 1.22v3.133c0 .438-.036.871-.104 1.298a4.651 4.651 0 0 0-3.23-1.298c-.874 0-1.693.241-2.393.66H8V2.127L3.333 4.2V8H8v.17A4.661 4.661 0 0 0 6 12c0 1.174.433 2.246 1.148 3.066C4.146 13.907 2 10.721 2 7.333V4.2c0-.527.313-1.007.793-1.22Z"
        clipRule="evenodd"
      />
      <g clipPath="url(#a)">
        <path
          fill={theme.colors['icon.danger']}
          stroke={theme.colors['icon.danger']}
          strokeWidth={0.5}
          d="M10.667 8.667a3.335 3.335 0 0 0 0 6.666A3.335 3.335 0 0 0 14 12a3.335 3.335 0 0 0-3.333-3.333Zm0 6A2.666 2.666 0 0 1 8 12c0-.617.21-1.183.563-1.633l3.737 3.736a2.634 2.634 0 0 1-1.633.564Zm2.103-1.034L9.034 9.897a2.634 2.634 0 0 1 1.633-.564A2.666 2.666 0 0 1 13.334 12c0 .617-.21 1.183-.564 1.633Z"
        />
      </g>
      <defs>
        <clipPath id="a">
          <path fill="#fff" d="M6.667 8h8v8h-8z" />
        </clipPath>
      </defs>
    </svg>
  );
};

export const Cloud = (props: SVGProps<SVGSVGElement>) => {
  const [, theme] = useStyletron();

  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
      <g clipPath="url(#clip0_6536_146985)">
        <path
          opacity="0.3"
          d="M12.8054 8.02667L11.7854 7.95333L11.5854 6.95333C11.252 5.24 9.74537 4 7.9987 4C6.62536 4 5.38536 4.76 4.74536 5.97333L4.41203 6.60667L3.6987 6.68C2.35203 6.82667 1.33203 7.96667 1.33203 9.33333C1.33203 10.8067 2.52536 12 3.9987 12H12.6654C13.7654 12 14.6654 11.1 14.6654 10C14.6654 8.96667 13.852 8.09333 12.8054 8.02667Z"
          fill={theme.colors['icon.primary']}
        />
        <path
          d="M12.9 6.69341C12.4467 4.39341 10.4267 2.66675 8 2.66675C6.07333 2.66675 4.4 3.76008 3.56667 5.36008C1.56 5.57341 0 7.27341 0 9.33341C0 11.5401 1.79333 13.3334 4 13.3334H12.6667C14.5067 13.3334 16 11.8401 16 10.0001C16 8.24008 14.6333 6.81341 12.9 6.69341ZM12.6667 12.0001H4C2.52667 12.0001 1.33333 10.8067 1.33333 9.33341C1.33333 7.96675 2.35333 6.82675 3.70667 6.68675L4.42 6.61341L4.75333 5.98008C5.38667 4.76008 6.62667 4.00008 8 4.00008C9.74667 4.00008 11.2533 5.24008 11.5933 6.95341L11.7933 7.95341L12.8133 8.02675C13.8533 8.09341 14.6667 8.96675 14.6667 10.0001C14.6667 11.1001 13.7667 12.0001 12.6667 12.0001Z"
          fill={theme.colors['icon.primary']}
        />
      </g>
      <defs>
        <clipPath id="clip0_6536_146985">
          <rect width="16" height="16" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};
