import { Result } from '@/lib/type';
import { getWorkSpaceDetail } from '@/pages/workgroup/api';
import { deletingSpaceIdsAtom, workSpacesAtom } from '@/pages/workgroup/atom';
import {
  WorkGroupT,
  WorkspaceT,
  calculatePropsForWorkspace,
  isStatusActive,
  isStatusError,
  isStatusPending,
  isStatusUnknown,
} from '@/pages/workgroup/type';
import { AxiosError } from 'axios';
import { useAtom } from 'jotai';
import { useQuery, useQueryClient } from 'react-query';
import { useTutorialContext } from '@/components/tutorial/tutorialContext.tsx';
import useProfile from '@/lib/useProfile';
import { useWorkspaceContext } from '@/contexts/workspaceContext';

const pollingInterval = 5 * 1000;

export function WorkspacesPoll() {
  const [workspaces] = useAtom(workSpacesAtom);

  const { data: profile } = useProfile();
  let userEmail = '';
  if (profile) {
    userEmail = profile.email;
  }

  return (
    <div>
      <div>
        {workspaces.map((workspace) => (
          <WorkspacePoll workspace={workspace} key={workspace.workspace_id} userEmail={userEmail} />
        ))}
      </div>
    </div>
  );
}

function WorkspacePoll({ workspace, userEmail }: { workspace: WorkspaceT; userEmail: string }) {
  const [, setWorkspaces] = useAtom(workSpacesAtom);
  const [deletingSpaceIds] = useAtom(deletingSpaceIdsAtom);
  const queryClient = useQueryClient();
  const { setShouldShowCongratulationModal, setWorkspace } = useTutorialContext();
  const { groupsAccess } = useWorkspaceContext();

  useQuery<Result<WorkspaceT>, AxiosError>(
    ['space', workspace.workspace_id],
    async () => {
      return getWorkSpaceDetail(workspace.workgroup_id, workspace.workspace_id);
    },
    {
      initialData: {
        Error: false,
        Result: workspace,
      },
      onError: (error: AxiosError) => {
        // when error, remove it from the polling list
        setWorkspaces((oldData) => {
          return oldData.filter((item) => item.workspace_id !== workspace.workspace_id);
        });

        // if http status is 404, the workspace could be deleted
        if (error.response?.status === 404) {
          queryClient.invalidateQueries(['group', workspace.workgroup_id]);
        }
      },
      onSuccess: (data) => {
        // update workgroup cache
        if (data?.Result) {
          const workspace = data?.Result;
          // if (isStatusPending(workspace.status)) {
          //   return;
          // }
          queryClient.setQueryData<Result<WorkGroupT> | undefined>(['group', workspace.workgroup_id], (oldData) => {
            if (!oldData?.Result) {
              return oldData;
            }
            const workgroup = oldData.Result;

            let newData = {
              ...oldData,
              Result: {
                ...oldData.Result,
                workspaces: oldData.Result.workspaces.map((w) =>
                  w.workspace_id !== workspace.workspace_id
                    ? w
                    : calculatePropsForWorkspace(workspace, workgroup, groupsAccess)
                ),
              },
            };
            return newData;
          });
        }

        // Polling until
        // 1. the status is not pending
        // 2. the status is not unknown
        // 3. the status is not error (means we will polling when the status is error)
        if (
          data?.Result &&
          !isStatusPending(data.Result.status) &&
          !isStatusUnknown(data.Result.status) &&
          !isStatusError(data.Result.status) &&
          !deletingSpaceIds.has(workspace.workspace_id)
        ) {
          queryClient.invalidateQueries(['groups']);

          // remove the workspace from the polling list
          setWorkspaces((oldData) => {
            return oldData.filter((item) => item.workspace_id !== workspace.workspace_id);
          });

          // only show notification for active status
          if (!isStatusActive(data.Result.status)) {
            return;
          }

          if (workspace.condition_type !== 'InitializeRoll' && workspace.condition_type !== 'InitializePost') {
            return;
          }

          // only show notification for the creator
          if (userEmail !== data.Result.creator) {
            return;
          }

          // show the congratulation popover
          setShouldShowCongratulationModal(true);
          setWorkspace(workspace);
        }
      },
      refetchInterval: (data) => {
        if (!data?.Result) {
          setWorkspaces((oldData) => {
            return oldData.filter((item) => item.workspace_id !== workspace.workspace_id);
          });
          return false;
        }

        const workspace = data?.Result;
        let shouldFetch = false;
        if (
          isStatusPending(workspace.status) ||
          isStatusUnknown(workspace.status) ||
          isStatusError(workspace.status) ||
          deletingSpaceIds.has(workspace.workspace_id)
        ) {
          shouldFetch = true;
        }

        if (!shouldFetch) {
          return false;
        }

        return pollingInterval;
      },
    }
  );

  return null;
}
