import { Drawer, DrawerAction, DrawerBody, DrawerHeader } from '@/components/Drawer';
import { Input } from '@tigergraph/app-ui-lib/input';
import { useStyletron } from '@tigergraph/app-ui-lib/Theme';
import { Button } from '@tigergraph/app-ui-lib/button';

import { useMutation, useQuery, useQueryClient } from 'react-query';
import { axiosController } from '@/lib/network';
import { useEffect, useState } from 'react';
import { Block } from 'baseui/block';
import useCopyClipboard from 'react-use-clipboard';
import { Body2 } from '@tigergraph/app-ui-lib/typography';
import { ProgressBar } from 'baseui/progress-bar';
import { showToast } from '@/components/styledToasterContainer';
import { getErrorMessage, validateBackupTag } from '@/utils/utils';
import { AxiosError } from 'axios';
import { BackupStatus } from '@/pages/workgroup/tab/backupRestore/workspaceBackup';

export interface BackupDrawerProps {
  isOpen: boolean;
  onClose: () => void;
  workgroupId: string;
  workspaceId: string;
  onBackupSuccess?: () => void;
}

function isBackupEnd(status: BackupStatus | null) {
  if (!status) {
    return false;
  }

  return (
    status === BackupStatus.BACKUP_SUCCEED ||
    status === BackupStatus.BACKUP_FAILED ||
    status === BackupStatus.BACKUP_FORBIDDEN
  );
}

export function BackupDrawer({ isOpen, onClose, workgroupId, workspaceId, onBackupSuccess }: BackupDrawerProps) {
  const [css, theme] = useStyletron();
  const [backupName, setBackupName] = useState('');
  const [backupId, setBackupId] = useState('');
  const [backupNameCopied, setBackupNameCopied] = useCopyClipboard(backupId, {
    successDuration: 1000,
  });
  const [backupLogs, setBackupLogs] = useState('');
  const [progress, setProgress] = useState(0);
  const [backupStatus, setBackupStatus] = useState<BackupStatus | null>(null);
  const backupFailed = backupStatus === BackupStatus.BACKUP_FAILED || backupStatus === BackupStatus.BACKUP_FORBIDDEN;
  const [intervalTimer, setIntervalTimer] = useState<number | null>(null);
  const [isBackupNameValid, setIsBackupNameValid] = useState(true);

  // estimate backup time is 180 seconds
  const maxEstimateProgress = 180;

  const queryClient = useQueryClient();
  const { mutate, isLoading } = useMutation(
    ['createBackup'],
    async (name: string) => {
      const response = await axiosController.post(`/v2/workgroups/${workgroupId}/workspaces/${workspaceId}/backups`, {
        name,
      });
      return response.data;
    },
    {
      onSuccess: (data) => {
        setBackupId(data.Result);
        queryClient.invalidateQueries(['group', workgroupId]);
        queryClient.invalidateQueries(['getBackupList', workspaceId]);
      },
      onError(error) {
        showToast({
          kind: 'negative',
          message: `${getErrorMessage(error as AxiosError)}`,
        });
      },
    }
  );

  const handleClose = () => {
    onClose && onClose();
  };

  useEffect(() => {
    if (backupId) {
      // fake progress
      // @ts-ignore
      const interval: number = setInterval(() => {
        setProgress((prev) => {
          if (prev < maxEstimateProgress - 1) {
            return prev + 1;
          } else {
            return prev;
          }
        });
      }, 1000);
      setIntervalTimer(interval);

      return () => clearInterval(interval);
    }
  }, [backupId]);

  useEffect(() => {
    if (isBackupEnd(backupStatus)) {
      intervalTimer && clearInterval(intervalTimer as number);
    }
  }, [backupStatus, intervalTimer]);

  useEffect(() => {
    if (backupNameCopied) {
      showToast({
        kind: 'positive',
        message: 'tag copied successfully.',
      });
    }
  }, [backupNameCopied]);

  useQuery(
    'queryBackupStatus',
    async () => {
      const response = await axiosController.get(
        `/v2/workgroups/${workgroupId}/workspaces/${workspaceId}/backups/${backupId}/status`
      );
      return response.data;
    },
    {
      onSuccess: (data) => {
        setBackupStatus(data.Result.status as BackupStatus);

        if (data.Result.status === BackupStatus.BACKUP_SUCCEED) {
          onBackupSuccess && onBackupSuccess();
        }
        if (data.Result.status === BackupStatus.BACKUP_FAILED || data.Result.status === BackupStatus.BACKUP_FORBIDDEN) {
          showToast({
            kind: 'negative',
            message: 'Backup failed for some reasons.',
          });
        }
        setBackupLogs(data.Result.output);
      },
      refetchInterval: 5000,
      enabled: !!backupId && !isBackupEnd(backupStatus),
    }
  );

  return (
    <Drawer
      isOpen={isOpen}
      onClose={({ closeSource }) => {
        if (closeSource === 'backdrop' || closeSource === 'escape') {
          return;
        }
        handleClose();
      }}
      overrides={{
        DrawerBody: {
          style: {
            overflowY: 'hidden',
          },
        },
      }}
    >
      <DrawerHeader>Backup</DrawerHeader>
      <DrawerBody
        $style={{
          display: 'flex',
          flexDirection: 'column',
          maxHeight: 'calc(100vh - 121px)',
        }}
      >
        <div
          className={css({
            color: theme.colors['text.secondary'],
          })}
        >
          TigerGraph Savanna provides you with a manual backup option in addition to the auto-scheduled backups. While
          auto-scheduled backups ensure regular data protection, manual backups allow you to create backups on-demand,
          giving you more control over when and how often you back up your data. Once the backup is started, you can
          also view the status and details of your manual backups, making it easy to keep track of your backup progress.
          Manual backups are especially useful when you need to capture a snapshot of your data at a specific point in
          time or before making significant changes.
        </div>
        <div
          className={css({
            borderBottom: `1px solid ${theme.colors.divider}`,
            margin: '16px -10px',
            width: 'calc(100% + 20px)',
          })}
        ></div>
        <div>Backup Tag</div>
        {backupId ? (
          <>
            <Block display={'flex'} marginTop={'16px'} flexDirection={'column'}>
              <Body2>Backup Progress</Body2>
              <Block display={'flex'} justifyContent={'space-between'} alignItems={'center'}>
                <ProgressBar
                  overrides={{
                    Root: {
                      style: {
                        width: '60%',
                        flexGrow: 1,
                      },
                    },
                    BarContainer: {
                      style: {
                        marginLeft: '0',
                        marginRight: '0',
                      },
                    },
                  }}
                  value={progress}
                  maxValue={maxEstimateProgress}
                />
              </Block>
              <span
                className={css({
                  marginTop: '-4px',
                  fontSize: '14px',
                  color: theme.colors['text.secondary'],
                })}
              >
                This may take a few minutes
              </span>
            </Block>
            <Body2 marginBottom={'8px'} marginTop={'16px'}>
              Backup Logs
            </Body2>
            <div
              className={css({
                whiteSpace: 'pre-line',
                fontSize: '12px',
                fontFamily: 'Monaco',
                padding: '10px',
                backgroundColor: `${theme.colors['background.secondary']}`,
                borderRadius: '4px',
                overflowY: 'auto',
                flexBasis: 0,
                flexGrow: 1,
              })}
            >
              {backupLogs}
            </div>
          </>
        ) : (
          <>
            <Input
              autoFocus
              value={backupName}
              onChange={(e) => {
                setBackupName(e.currentTarget.value);
                validateBackupTag(e.currentTarget.value) && setIsBackupNameValid(true);
              }}
              overrides={{
                Root: {
                  style: {
                    margin: '8px 0',
                  },
                },
              }}
            />
            {!isBackupNameValid && (
              <div
                className={css({
                  color: theme.colors.negative,
                })}
              >
                Please ensure that the string only contains characters ([a-zA-Z0-9]), hyphens (-), and underscores (_).
              </div>
            )}
          </>
        )}
      </DrawerBody>
      <DrawerAction>
        <Button kind="secondary" onClick={handleClose} size="large">
          Cancel
        </Button>
        <Button
          size="large"
          isLoading={isLoading}
          disabled={backupId !== '' || isLoading}
          onClick={() => {
            if (validateBackupTag(backupName)) {
              mutate(backupName);
            } else {
              setIsBackupNameValid(false);
            }
          }}
        >
          Backup
        </Button>
      </DrawerAction>
    </Drawer>
  );
}
