import { Tabs, Tab, pageTabsOverrides } from '@/components/Tab';
import { styled, useStyletron } from '@tigergraph/app-ui-lib/Theme';
import { Body2, HeadingMenuSelected, Label } from '@tigergraph/app-ui-lib/typography';
import { ReactNode, useState } from 'react';
import { Skeleton } from 'baseui/skeleton';

import SpaceIcon from './icons/workspace.svg?react';
import SolutionIcon from './icons/solution.svg?react';
import SchemaIcon from './icons/schema.svg?react';
import LoadIcon from './icons/loading.svg?react';
import EditorIcon from './icons/editor.svg?react';
import ExploreIcon from './icons/graph.svg?react';
import UserIcon from './icons/user.svg?react';
import FeedbackIcon from './icons/feedback.svg?react';
import { Link, useNavigate } from 'react-router-dom';
import { MetaAddonsType, useAddonsMeta } from '@/hooks/useAddonsMeta';
import { EnabledAddonsType, useListEnabledAddons } from '@/hooks/useListEnabledAddons';
import { ErrorDisplay } from '@/components/error';
import { AddonsMetaID } from '@/components/addons/consts';
import eventemitter from '@/lib/eventEmitter';
import { useQueryGetGroups } from '@/pages/workgroup/hook';
import { parseDate, timeSince } from '@/lib/date';
import { SpaceIcon as SmallSpaceIcon } from '@/pages/home/icons';
import { StatusIndicator } from '@/pages/workgroup/tab/General';
import ViewAddonsWorkspaceDrawer from '@/pages/marketplace/addon/ViewAddonsWorkspaceDrawer';
import DocIcon from './icons/doc.svg?react';
import UserGuide from '@/components/userguide';
import { useUserGuideContext } from '@/components/userguide/userGuideContext';
import PluginIcon from '@/assets/plugin.svg?react';
import { useOrgContext } from '@/contexts/orgContext';
import { isOrgAdmin } from '@/pages/admin/user/type';
import { isStatusActive } from '@/pages/workgroup/type';
import { CreateWorkspaceModal } from '@/components/CreateWorkspaceModal';
import { useCanCreateWorkspaceInWorkspaceSelector } from '@/pages/editor/WorkspaceSelector';

const transformEnabledAddonsToMap = (data?: EnabledAddonsType[]) => new Map(data?.map((item) => [item.AddonsID, item]));

export default function Home() {
  const [css, theme] = useStyletron();
  const navigate = useNavigate();

  const { userInfo } = useOrgContext();
  const isOrgAdminRole = isOrgAdmin(userInfo.roles);
  const { canCreateWorkspace } = useCanCreateWorkspaceInWorkspaceSelector();

  const { isLoading: isLoadingMeta, data: addonsMeta, error: listMetaError } = useAddonsMeta();
  const {
    isLoading: isLoadingEnabledAddons,
    data: enableAddonsItemsMap,
    error: listEnabledAddonsError,
  } = useListEnabledAddons({
    select: transformEnabledAddonsToMap,
  });
  const [isViewOpen, setViewOpen] = useState(false);
  const [selectedAddonsMeta, setSelectedAddonsMeta] = useState<MetaAddonsType | undefined>(undefined);
  const [isCreateWorkspaceModalOpen, setCreateWorkspaceModalOpen] = useState(false);

  const isLoading = isLoadingMeta || isLoadingEnabledAddons;
  const error = listMetaError || listEnabledAddonsError;

  const { allFinished } = useUserGuideContext();

  const { data: groups } = useQueryGetGroups();
  const hasActiveRWorkspace = groups?.Result?.some((group) =>
    group.workspaces.some((workspace) => workspace.is_rw && isStatusActive(workspace.status))
  );
  const hasActiveWorkspace = groups?.Result?.some((group) =>
    group.workspaces.some((workspace) => isStatusActive(workspace.status))
  );

  if (error) {
    return <ErrorDisplay error={error} />;
  }

  const addons = addonsMeta?.AddonsList?.filter((item) => enableAddonsItemsMap?.has(item.ID)) || [];

  return (
    <>
      <CreateWorkspaceModal isOpen={isCreateWorkspaceModalOpen} onClose={() => setCreateWorkspaceModalOpen(false)} />
      <Tabs activeKey="landing" overrides={pageTabsOverrides}>
        <Tab title="Welcome to TigerGraph Savanna" key="landing">
          <div
            className={css({
              display: 'flex',
              flexDirection: 'column',
              padding: '0 8px',
              gap: '16px',
            })}
          >
            <HeadingMenuSelected>Quick Start</HeadingMenuSelected>
            <SectionContainer>
              <Item
                icon={<SpaceIcon />}
                title="Create Workspace"
                description="Create an independent, scalable compute environment."
                onClick={() => {
                  setCreateWorkspaceModalOpen(true);
                }}
                disabled={!canCreateWorkspace}
              />
              <Item
                icon={<SolutionIcon />}
                title="Install Solution"
                description="Deploy pre-packaged graph solutions with schemas, queries, and sample data."
                onClick={() => {
                  navigate('/marketplace/solutions');
                }}
                disabled={!hasActiveRWorkspace}
              />
              <Item
                icon={<SchemaIcon />}
                title="Design Schema"
                description="Model your data using vertices and edges."
                onClick={() => {
                  navigate('/design-schema');
                }}
                disabled={!hasActiveRWorkspace}
              />
              <Item
                icon={<LoadIcon />}
                title="Load Data"
                description="Import and format your data into the graph database."
                onClick={() => {
                  navigate('/ingestion');
                }}
                disabled={!hasActiveRWorkspace}
              />
              <Item
                icon={<EditorIcon />}
                title="Write Query"
                description="Write and execute GSQL or openCypher queries."
                onClick={() => {
                  navigate('/editor');
                }}
                disabled={!hasActiveWorkspace}
              />
              <Item
                icon={<ExploreIcon />}
                title="Explore Graph"
                description="Visually navigate and analyze your graph data."
                onClick={() => {
                  navigate('/explore');
                }}
                disabled={!hasActiveWorkspace}
              />
              <Item
                icon={<UserIcon />}
                title="Invite User"
                description="Invite team members to collaborate on projects."
                onClick={() => {
                  navigate('/admin/users', {
                    state: {
                      showInvite: true,
                    },
                  });
                }}
                disabled={!isOrgAdminRole}
              />
              <Item
                icon={<FeedbackIcon />}
                title="Give Feedback"
                description="Provide your suggestions to the TigerGraph team."
                onClick={() => {
                  eventemitter.emit('feedback');
                }}
              />
            </SectionContainer>

            <HeadingMenuSelected>Add-Ons</HeadingMenuSelected>
            <SectionContainer>
              {isLoading ? (
                <Addonskeletion />
              ) : addons.length > 0 ? (
                <>
                  {addons.map((addon) => (
                    <AddOnItem
                      key={addon.ID}
                      addOnType={addon}
                      onClick={() => {
                        setSelectedAddonsMeta(addon);
                        setViewOpen(true);
                      }}
                    />
                  ))}
                  {[...Array(4 - addons.length)].map((i) => (
                    <div key={i} />
                  ))}
                </>
              ) : (
                <div
                  className={css({
                    gridColumn: '1/-1',
                    padding: '16px',
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    justifyContent: 'center',
                    background: theme.colors['background.secondary'],
                  })}
                >
                  <PluginIcon width={31} />
                  <Label $style={{ gridColumn: '1/-1' }}>
                    {`Your organization hasn't added add-on yet.`}{' '}
                    {isOrgAdminRole ? (
                      <Link
                        className={css({
                          color: theme.colors.linkText,
                        })}
                        to="/marketplace/addons"
                      >
                        Click to add.
                      </Link>
                    ) : null}
                  </Label>
                </div>
              )}
            </SectionContainer>
            <div
              className={css({
                display: 'grid',
                gridTemplateColumns: 'repeat(auto-fit, minmax(min(100%, 280px), 1fr))',
                gap: '24px',
              })}
            >
              {allFinished ? null : (
                <ContentContainer>
                  <HeadingMenuSelected>New User To-Do List</HeadingMenuSelected>
                  <UserGuide />
                </ContentContainer>
              )}
              <ContentContainer>
                <HeadingMenuSelected>Tutorials</HeadingMenuSelected>
                <Tutorial />
              </ContentContainer>
              <ContentContainer>
                <HeadingMenuSelected>Recent Workspaces</HeadingMenuSelected>
                <RecentWorkspace />
              </ContentContainer>
            </div>
          </div>
        </Tab>
      </Tabs>
      <ViewAddonsWorkspaceDrawer
        isOpen={isViewOpen}
        onClose={() => setViewOpen(false)}
        selectedAddonsMeta={selectedAddonsMeta}
      />
    </>
  );
}

const SectionContainer = styled('div', {
  display: 'grid',
  gridTemplateColumns: 'repeat(4, 1fr)',
  gap: '16px',
});

const ItemTitle = styled('span', {
  lineHeight: '16px',
  fontWeight: '600',
  paddingTop: '12px',
  paddingBottom: '4px',
});

type Props = {
  icon: ReactNode;
  title: string;
  description: string;
  onClick: () => void;
  disabled?: boolean;
};

function Item({ icon, title, description, onClick, disabled }: Props) {
  const [css, theme] = useStyletron();
  return (
    <button
      onClick={onClick}
      className={css({
        borderRadius: '2px',
        border: `1px solid ${theme.colors['border.tertiary']}`,
        padding: '24px 24px 16px',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        textAlign: 'center',
        color: 'inherit',
        opacity: disabled ? 0.5 : 1,
        cursor: disabled ? 'not-allowed' : 'pointer',
      })}
      disabled={disabled}
    >
      <span>{icon}</span>
      <ItemTitle>{title}</ItemTitle>
      <Label
        $style={{
          minHeight: '32px',
        }}
      >
        {description}
      </Label>
    </button>
  );
}

const addOnDescriptions: Record<string, string> = {
  [AddonsMetaID.COPILOT]: 'An AI-powered assistant within TigerGraph Savanna that boosts productivity.',
  [AddonsMetaID.INSIGHTS]: 'Build graph powered applications in minutes.',
  [AddonsMetaID.GRAPH_STUDIO]: 'Design, develop, map and load your graph database.',
  [AddonsMetaID.GRAPHQL]: 'Query and update your graph data at ease via GraphQL API.',
};

function AddOnItem({ addOnType, onClick }: { addOnType: MetaAddonsType; onClick: () => void }) {
  const [css, theme] = useStyletron();
  let { Avatar: icon, Title: title, ID: id } = addOnType;

  return (
    <button
      onClick={onClick}
      className={css({
        borderRadius: '2px',
        border: `1px solid ${theme.colors['border.tertiary']}`,
        padding: '16px 16px 8px 16px',
        display: 'flex',
        gap: '8px',
        color: 'inherit',
        textAlign: 'left',
      })}
    >
      <img
        className={css({ width: '40px', height: '40px', boxSizing: 'content-box', padding: '7px 5px' })}
        src={icon}
      />
      <span>
        <ItemTitle>{title}</ItemTitle>
        <Label>{addOnDescriptions[id]}</Label>
      </span>
    </button>
  );
}

const Addonskeletion = () => {
  const [css, theme] = useStyletron();
  return (
    <>
      {[...Array(4)].map((_, idx) => (
        <div
          key={idx}
          className={css({
            borderRadius: '2px',
            border: `1px solid ${theme.colors.divider}`,
            padding: '16px 16px 24px',
            display: 'flex',
            gap: '8px',
          })}
        >
          <Skeleton height="56px" width="56px" animation />
          <div
            className={css({
              flex: 1,
              display: 'flex',
              flexDirection: 'column',
              gap: '16px',
            })}
          >
            <Skeleton height="24px" animation />
            <Skeleton rows={3} animation />
          </div>
        </div>
      ))}
    </>
  );
};

const ContentContainer = styled('div', {
  display: 'flex',
  flexDirection: 'column',
  gap: '8px',
});

const Content = styled('div', ({ $theme }) => {
  return {
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
    gap: '8px',
    padding: '24px',
    backgroundColor: $theme.colors['background.secondary'],
    alignItems: 'flex-start',
  };
});

function LinkButton({ children, onClick }: { children: ReactNode; onClick: () => void }) {
  const [css, theme] = useStyletron();
  return (
    <button
      onClick={onClick}
      className={css({
        color: theme.colors['text.link'],
        display: 'inline-flex',
        gap: '8px',
      })}
    >
      <DocIcon />
      <Body2 $style={{ color: 'inherit' }}>{children}</Body2>
    </button>
  );
}

function Tutorial() {
  const [css, theme] = useStyletron();
  return (
    <Content>
      <LinkButton
        onClick={() => {
          eventemitter.emit('tutorial', 'workspace');
        }}
      >
        Getting Started
      </LinkButton>
      <LinkButton
        onClick={() => {
          eventemitter.emit('tutorial', 'groups', '11');
        }}
      >
        Create Workspace
      </LinkButton>
      <LinkButton
        onClick={() => {
          eventemitter.emit('tutorial', 'groups', '13');
        }}
      >
        Monitor Workspace
      </LinkButton>
      <LinkButton
        onClick={() => {
          eventemitter.emit('tutorial', 'groups', '12');
        }}
      >
        Create Read-Only Workspace
      </LinkButton>
      <LinkButton
        onClick={() => {
          eventemitter.emit('tutorial', 'groups', '15');
        }}
      >
        Get Data Profile
      </LinkButton>
      <LinkButton
        onClick={() => {
          eventemitter.emit('tutorial', 'groups', '16');
        }}
      >
        Connect From API
      </LinkButton>
      <LinkButton
        onClick={() => {
          eventemitter.emit('tutorial', 'ingestion', '21');
        }}
      >
        Load Local CSV File
      </LinkButton>
      <LinkButton
        onClick={() => {
          eventemitter.emit('tutorial', 'ingestion', '22');
        }}
      >
        Load From Amazon S3
      </LinkButton>
      <LinkButton
        onClick={() => {
          eventemitter.emit('tutorial', 'editor', '31');
        }}
      >
        Develop Using GSQL Editor
      </LinkButton>
      <LinkButton
        onClick={() => {
          window.open('https://docs.tigergraph.com/gsql-ref/current/tutorials/gsql-101/', '_blank');
        }}
      >
        GSQL 101
      </LinkButton>
      <LinkButton
        onClick={() => {
          eventemitter.emit('tutorial', 'explore', '41');
        }}
      >
        Explore Graph Using Pattern Search
      </LinkButton>
      <button
        className={css({ alignSelf: 'flex-end', marginTop: 'auto' })}
        onClick={() => {
          eventemitter.emit('tutorial');
        }}
      >
        <Label $style={{ color: theme.colors['text.link'] }}>{`More >>`}</Label>
      </button>
    </Content>
  );
}

function RecentWorkspace() {
  const [css, theme] = useStyletron();
  const { isLoading, data } = useQueryGetGroups();

  const spaces =
    data?.Result?.map((group) => group.workspaces)
      .flat()
      .sort((sp1, sp2) => (parseDate(sp1.created_at) < parseDate(sp2.created_at) ? 1 : -1))
      .slice(0, 5) || [];

  return (
    <Content
      $style={{
        alignSelf: 'stretch',
      }}
    >
      {isLoading ? (
        <>
          {Array(5)
            .fill(0)
            .map((_, index) => (
              <Skeleton height="16px" animation width="100%" key={index} />
            ))}
        </>
      ) : spaces.length > 0 ? (
        spaces.map((space) => {
          return (
            <div
              key={space.workspace_id}
              className={css({
                display: 'flex',
                flexWrap: 'wrap',
                alignItems: 'center',
                gap: '4px',
                width: '100%',
              })}
            >
              <SmallSpaceIcon />
              <div className={css({ display: 'flex', gap: '4px' })}>
                <Body2 $style={{ color: theme.colors['text.tertiary'], fontSize: '14px' }}>
                  {space.workgroup_name} /
                </Body2>
                <Body2>
                  <Link to={'/groups/' + space.workgroup_id}>{space.name}</Link>
                </Body2>
              </div>
              <div
                className={css({
                  marginRight: 'auto',
                })}
              >
                <StatusIndicator status={space.status} size="compact" />
              </div>
              <Body2
                $style={{
                  color: theme.colors['text.secondary'],
                  fontSize: '12px',
                }}
              >
                {timeSince(parseDate(space.created_at))} ago
              </Body2>
            </div>
          );
        })
      ) : (
        <div>There are no recent workspaces available at the moment.</div>
      )}
    </Content>
  );
}
