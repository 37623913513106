import { Drawer, DrawerAction, DrawerBody, DrawerHeader } from '@/components/Drawer';
import { useMutationInviteUser, useQueryListUsers } from '@/pages/admin/user/hook';
import { Button } from '@tigergraph/app-ui-lib/button';
import { Input } from '@tigergraph/app-ui-lib/input';
import { StatefulPopover } from '@tigergraph/app-ui-lib/popover';
import { TRIGGER_TYPE } from 'baseui/popover';
import { Radio, RadioGroup } from 'baseui/radio';
import useHover from 'ahooks/lib/useHover';
import { getErrorMessage } from '@/utils/utils';
import { showToast } from '@/components/styledToasterContainer';
import { useStyletron } from '@tigergraph/app-ui-lib/Theme';
import DeleteIcon from '@/assets/delete.svg?react';
import InfoIcon from '@/assets/info.svg?react';
import React, { useRef, useState } from 'react';
import { leftRadioOverrides, rightRadioOverrides } from '@/components/radio';
import { FormControl } from '@tigergraph/app-ui-lib/form-control';
import { User, UserData } from '@/pages/admin/user/type';
import { useUserGuideContext } from '@/components/userguide/userGuideContext';
import { OnboardingTaskName } from '@/components/userguide/utils';
import { RoleType } from '@/lib/models';
import { DrawerHeadContainer } from '@/lib/styled.tsx';
import { UserIconXL } from '@/pages/home/icons.tsx';

export type Props = {
  isOpen: boolean;
  onClose: () => void;
};

export default function InviteUser({ isOpen, onClose }: Props) {
  const inviteUserMutation = useMutationInviteUser();
  const [css] = useStyletron();
  const { completeOnboardingTask } = useUserGuideContext();

  const { data: allUser = [] } = useQueryListUsers();
  const [userInput, setUserInput] = useState('');
  const [usersToAdd, setUsersToAdd] = useState<UserData[]>([]);

  const enterToAddInvitingUser = (e: React.KeyboardEvent) => {
    if (e.key === 'Enter' && userInput && !validateUser(userInput, allUser, usersToAdd)) {
      addInvitingUser();
    }
  };

  function addInvitingUser() {
    setUsersToAdd([...usersToAdd, { email: userInput, roles: ['users'] }]);
    setUserInput('');
  }

  function changeInvitingUserRole(index: number, role: RoleType) {
    usersToAdd[index].roles = [role];
    setUsersToAdd([...usersToAdd]);
  }

  function removeInvitingUser(user: UserData) {
    const index = usersToAdd.findIndex((item) => item.email === user.email);
    setUsersToAdd(usersToAdd.slice(0, index).concat(usersToAdd.slice(index + 1)));
  }

  return (
    <Drawer isOpen={isOpen} onClose={onClose}>
      <DrawerHeader>
        <DrawerHeadContainer>
          <UserIconXL />
          Invite a New User to Your Organization
        </DrawerHeadContainer>
      </DrawerHeader>
      <DrawerBody
        $style={{
          display: 'flex',
          flexDirection: 'column',
          gap: '16px',
        }}
      >
        <div
          className={css({
            display: 'flex',
            alignItems: 'center',
            gap: '8px',
            fontSize: '14px',
            fontWeight: '600',
          })}
        >
          <span>Add new users to your organization</span>
          <StatefulPopover triggerType={TRIGGER_TYPE.hover} content="You invite any users to your organization.">
            <Button kind="text" shape="square">
              <InfoIcon />
            </Button>
          </StatefulPopover>
        </div>
        <div
          className={css({
            display: 'flex',
            alignItems: 'flex-start',
            gap: '20px',
          })}
        >
          <FormControl
            error={validateUser(userInput, allUser, usersToAdd)}
            overrides={{
              ControlContainer: {
                style: {
                  marginBottom: '0px',
                },
              },
            }}
          >
            <Input
              placeholder="Add email"
              value={userInput}
              onChange={(e) => setUserInput(e.currentTarget.value)}
              onKeyDown={enterToAddInvitingUser}
            />
          </FormControl>
          <Button
            size="large"
            onClick={addInvitingUser}
            disabled={!userInput || !!validateUser(userInput, allUser, usersToAdd)}
          >
            Add
          </Button>
        </div>
        <div
          className={css({
            display: 'flex',
            flexDirection: 'column',
            gap: '4px',
          })}
        >
          {usersToAdd.map((user, index) => (
            <UserItem
              key={user.email}
              userData={user}
              onChangeRole={(role) => changeInvitingUserRole(index, role)}
              onDelete={() => removeInvitingUser(user)}
            />
          ))}
        </div>
      </DrawerBody>
      <DrawerAction>
        <Button type="button" onClick={onClose} kind="secondary" size="large">
          Cancel
        </Button>
        <Button
          type="button"
          size="large"
          disabled={usersToAdd.length === 0}
          onClick={async () => {
            inviteUserMutation.mutate(
              { users: usersToAdd },
              {
                onSuccess: async () => {
                  showToast({
                    kind: 'positive',
                    message: 'Invitation send successfully.',
                  });

                  onClose();

                  completeOnboardingTask(OnboardingTaskName.inviteMember);
                },
                onError: (error) => {
                  showToast({
                    kind: 'negative',
                    message: getErrorMessage(error),
                  });
                },
              }
            );
          }}
          isLoading={inviteUserMutation.isLoading}
        >
          Invite
        </Button>
      </DrawerAction>
    </Drawer>
  );
}

type ItemProps = {
  userData: UserData;
  onDelete: () => void;
  onChangeRole: (role: RoleType) => void;
};
function UserItem({ userData, onDelete, onChangeRole }: ItemProps) {
  const [css, theme] = useStyletron();
  const ref = useRef<HTMLDivElement>(null);
  const hover = useHover(ref);
  return (
    <div
      className={css({
        display: 'grid',
        columnGap: '4px',
        alignItems: 'center',
        gridTemplateColumns: '1fr auto 20px',
        padding: '4px 8px',
        ':hover': {
          backgroundColor: theme.colors['background.primary.hover'],
        },
      })}
      ref={ref}
    >
      <span className={css({ flex: 1 })}>{userData.email}</span>
      <RadioGroup
        align="horizontal"
        value={userData.roles[0]}
        onChange={(e) => onChangeRole(e.currentTarget.value as RoleType)}
      >
        <Radio value="super-admins" overrides={leftRadioOverrides}>
          Org Admin
        </Radio>
        <Radio value="users" overrides={rightRadioOverrides}>
          Org Member
        </Radio>
      </RadioGroup>
      {hover ? (
        <Button kind="text" shape="square" onClick={onDelete}>
          <span
            className={css({
              color: theme.colors['icon.danger'],
            })}
          >
            <DeleteIcon />
          </span>
        </Button>
      ) : null}
    </div>
  );
}

function validateUser(userInput: string, allUser: User[], usersToAdd: UserData[]) {
  if (userInput) {
    if (!/^\S+@\S+\.[A-Za-z]+$/.test(userInput)) {
      return 'Please input a valid email address';
    } else if (allUser.map((user) => user.email).includes(userInput)) {
      return 'This user is already in your organization';
    } else if (usersToAdd.map((user) => user.email).includes(userInput)) {
      return 'This user is already in the adding list';
    }
  }
  return undefined;
}
