import { useStyletron } from '@tigergraph/app-ui-lib/Theme';
import { Body2 } from '@tigergraph/app-ui-lib/typography';

import { StyledSubTitle, StyledText, StyledTitle, InlineBlock } from './Style.tsx';

export const TermsConditions = () => {
  const [css, theme] = useStyletron();

  return (
    <InlineBlock style={{ flexDirection: 'column', gap: '16px' }}>
      <StyledText>
        These TigerGraph Savanna Subscription Terms (“Subscription Terms”) are part of a legal agreement (the
        “Agreement”) between the person or entity agreeing to these terms (“Customer”) and TigerGraph, Inc., a Delaware
        corporation, and includes these Subscription Terms, any terms expressly incorporated herein by reference, and
        any terms in a fully executed ordering document that incorporates these Subscription Terms (an “Order
        Schedule”). This Agreement is effective as of the date the Order Schedule is fully executed (the “Effective
        Date”).
      </StyledText>
      <StyledTitle>1. Subscription</StyledTitle>
      <StyledText>
        1.1. Subscription Services. Subject to the provisions of these Subscription Terms, TigerGraph will provide
        certain cloud-based Software services (“Cloud Services”) as specified on an Order Schedule for the period
        specified in the applicable Order Schedule (the “Subscription Period”). The Cloud Services will be provided in
        accordance with the: (i) Order Schedule (ii) Documentation, (iii) SLA, and (iv) Support Policy.
      </StyledText>
      <StyledText>
        1.2 Definitions.
        <br />
        “Software” means the TigerGraph Enterprise database software and related tools.
        <br />
        “Console” means the web-based user interface that Customer shall use to access the Cloud Services.
        <br />
        “Customer Data” means all proprietary, non-public electronic data or information submitted to the Cloud Services
        by Customer.
        <br />
        “Documentation” means the materials available at docs.tigergraph.com, as amended from time to time.
        <br />
        “SLA” means the TigerGraph Savanna SLA available at www.tigergraph.com/terms, as amended from time to time.
        <br />
        “Support Policy” means the terms set forth in the TigerGraph Support Policy available at
        www.tigergraph.com/terms as amended from time to time.
        <br />
      </StyledText>
      <StyledText>
        1.3 Modifications; Discontinuation of Service. TigerGraph may make modifications to the Cloud Services or
        components of the Cloud Services, including the Software, from time to time and will use commercially reasonable
        efforts to notify Customer of any material modifications. TigerGraph reserves the right to discontinue offering
        the Cloud Services at the conclusion of Customer’s then-current Subscription Period.
      </StyledText>
      <StyledText>
        1.4 TigerGraph Savanna Free Tier Users and Free Credit. TigerGraph may allow Customer to use the Cloud Services
        on a no-fee basis, including through the provision of free credit that can be used on Cloud Services. If such
        no-fee use or free credit is allowed, TigerGraph shall not be subject to the SLA, Support Policy, and the
        warranties set forth in Section 7.1 and 7.2. Customer’s free use of the TigerGraph Savanna Services may be
        terminated at any time at TigerGraph’s discretion.
      </StyledText>
      <StyledTitle>2. Payment</StyledTitle>
      <StyledText>
        2.1. Fees. Customer will pay all fees specified in the Order Schedule for Cloud Services (“Fees”). If the Order
        Schedule specifies a subscription basis, all Fees are due 30 days from the invoice date. If the Order Schedule
        specifies a pay-as-you-go basis, Customer shall provide a credit card for the payment of Fees. For the
        pay-as-you-go basis, Customer authorizes a recurring monthly charge to your credit card based on our current fee
        schedule for the Cloud Services. All Fees are non-refundable and non-cancelable. Unless otherwise specified in
        the Order Schedule, all Fees are in U.S. dollars and shall be paid without setoff or deduction. Unless otherwise
        specified in the applicable Order Schedule, upon renewal of the Subscription Period, the Fees will be the
        then-current list price for Cloud Services.
      </StyledText>
      <StyledText>
        2.2. Taxes. Customer is responsible for any duties, customs fees, or taxes (other than TigerGraph’s income tax)
        associated with the Cloud Services and professional services, including any related penalties or interest
        (“Taxes”), and shall pay the listed Fees without deduction for Taxes. If Customer is required by applicable law
        to withhold any amount from any payments specified in the Order Schedule, then Customer will pay TigerGraph the
        listed Fees as if no withholding were required, and shall remit the withholding to the appropriate governmental
        authorities on behalf of TigerGraph, with a copy of the tax receipt or certificate forwarded to TigerGraph.
      </StyledText>
      <StyledText>
        2.3. Delinquencies. Any late payments will accrue late charges at the rate of 1.5% of the outstanding balance
        per month, or the maximum rate permitted by law, whichever is lower.
      </StyledText>
      <StyledText>
        2.4 Bursting. Customer agrees that the Cloud Services may enable Customer to exceed the use limits defined in
        the Service Order, provided that if such use is repeated or excessive, as determined by TigerGraph, TigerGraph
        may invoice Customer, and Customer will pay for, the number of required additional use, prorated over the
        remaining Subscription Period. In no event shall Customer’s no-charge bursting use exceed 50 percent of the use
        amount for the Subscription Period, and any no-charge bursting shall be limited to thirty (30) total days within
        the Subscription Period. If Customer declines to increase the use limits as recommended by TigerGraph or
        otherwise refuses to cooperate with TigerGraph to increase its licensed use, TigerGraph may suspend Customer’s
        use of the Cloud Services, until such time as Customer is able to reduce its use to the licensed levels.
      </StyledText>

      <StyledTitle>3. Restrictions</StyledTitle>
      <StyledText>
        All rights in the Software, Console, and Cloud Services not expressly granted under this Agreement are reserved
        to TigerGraph or its licensors. Without limiting the foregoing (except to the extent such restriction is
        expressly prohibited by applicable law), Customer shall not, and shall not permit others under its control to:
        (1) copy, modify, create a derivative work of, reverse engineer, decompile, translate, disassemble, or otherwise
        attempt to extract the source code of the Software, Console, or any component of the Cloud Services; (2) assign,
        sublicense, transfer, lease, rent or otherwise distribute or make available the Software or Console, or Cloud
        Services to any third party; (3) use the Cloud Services for High Risk Activities or otherwise contrary to the
        Documentation; (4) use the Cloud Services to create, deliver training on, improve (directly or indirectly) or
        offer a substantially similar product or service; (5) reconfigure the Cloud Services without prior written
        consent from TigerGraph; (6) use the included ODBC driver to connect to other distributions of the components of
        the Software, or (7) perform benchmarking of the Cloud Services. “High Risk Activities” means uses such as the
        operation of nuclear facilities, air traffic control, or life support systems, where the use or failure of the
        Software could lead to death, personal injury, or environmental damage. The Software includes the third party
        software and material specified at https://docs.tigergraph.com (“Third Party Software”).
      </StyledText>

      <StyledTitle>4. Proprietary Rights; Data</StyledTitle>
      <StyledText>
        4.1. License. The Cloud services do not include the separate licensing or any sale of Software. Subject to the
        provisions of these Subscription Terms and Order Schedule, TigerGraph grants Customer a worldwide, nonexclusive,
        nontransferable, nonsublicensable, terminable license to access the Software provided with the Cloud Services
        during the Subscription Period, subject to any license parameters specified in the applicable Order Schedule. As
        between the parties, all intellectual property rights in the Software and Cloud Services shall remain with
        TigerGraph or its licensors. Software and Cloud Services may be updated at any time. If TigerGraph materially
        reduces the Cloud Services, if practicable, TigerGraph will provide reasonable notice of no less than six months
        prior notice to Customer.
      </StyledText>
      <StyledText>
        4.2. Federal Agency Users. The Software was developed solely at private expense and is commercial computer
        software and related documentation within the meaning of the applicable Federal Acquisition Regulation and
        agency supplements thereto.
      </StyledText>
      <StyledText>
        4.3. Feedback. If Customer provides any suggestions or feedback regarding the Software, Console, or Cloud
        Services, TigerGraph may use such information without obligation to Customer, and Customer hereby irrevocably
        assigns to TigerGraph all right, title, and interest in that feedback or those suggestions.
      </StyledText>
      <StyledText>
        4.4. Customer Data. As between TigerGraph and Customer, all title and intellectual property rights in and to the
        Customer Data is owned exclusively by Customer. Customer is solely responsible for the content of Customer data.
        Customer will not expose sensitive or regulated Customer Data in unencrypted format to TigerGraph. Customer
        hereby authorizes and consents to TigerGraph access, process, and store Customer Data solely as required to
        provide the Cloud Services. Customer will obtain any consents from Customer’s end users that are required for
        Customer to pass the Customer Data to TigerGraph to provide the Cloud Services.
      </StyledText>
      <StyledText>
        4.5. HIPAA. Unless Customer and TigerGraph are parties to a Business Associate Agreement, Customer agrees that:
        (i) TigerGraph is not acting on Customer’s behalf as a Business Associate or subcontractor; (ii) the Software
        may not be used to store, maintain, process or transmit protected health information (“PHI”); (iii) the Cloud
        Services will not be used in any manner that would require TigerGraph, the Software, or the Cloud Services to be
        compliant with the Health Insurance Portability and Accountability Act of 1996, as amended and supplemented
        (“HIPAA”). In the preceding sentence, the terms “Business Associate,” “subcontractor,” “protected health
        information” or “PHI” shall have the meanings described in HIPAA.
      </StyledText>
      <StyledText>
        4.6. Access for Support. Customer agrees to allow TigerGraph to access their environment for the purpose of
        providing support and maintenance services. This access will be conducted in accordance with TigerGraph&apos;s
        Support Policy and will be limited to the actions necessary to resolve support issues or perform maintenance
        tasks. TigerGraph will ensure that such access is conducted securely and in compliance with applicable privacy
        and data protection laws.
      </StyledText>
      <StyledText>
        4.7. GDPR. Should Customer Data contain personal data as defined by The EU general data protection regulation
        2016/679 “GDPR,” this Agreement shall be a contract as specified by Article 28(3) of the GDPR. Customer agrees
        that it shall comply with its obligations as a controller under the GDPR. In its role as a processor under the
        GDPR, TigerGraph agrees that it:
        <br />
        (a) processes the personal data only on documented instructions from the controller, including with regard to
        transfers of personal data to a third country or an international organization, unless required to do so by
        Union or Member State law to which the processor is subject; in such a case, the processor shall inform the
        controller of that legal requirement before processing, unless that law prohibits such information on important
        grounds of public interest;
        <br />
        (b) ensures that persons authorized to process the personal data have committed themselves to confidentiality or
        are under an appropriate statutory obligation of confidentiality;
        <br />
        (c) takes all measures required pursuant to Article 32 of the GDPR;
        <br />
        (d) respects the conditions referred to in paragraphs 2 and 4 of Article 28 of the GDPR for engaging another
        processor;
        <br />
        (e) taking into account the nature of the processing, assists the controller by appropriate technical and
        organizational measures, insofar as this is possible, for the fulfilment of the controller’s obligation to
        respond to requests for exercising the data subject’s rights laid down in Chapter III of the GDPR;
        <br />
        (f) assists the controller in ensuring compliance with the obligations pursuant to Articles 32 to 36 of the GDPR
        taking into account the nature of processing and the information available to the processor;
        <br />
        (g) at the choice of the controller, deletes or returns all the personal data to the controller after the end of
        the provision of services relating to processing, and deletes existing copies unless Union or Member State law
        requires storage of the personal data;
        <br />
        (h) makes available to the controller all information necessary to demonstrate compliance with the obligations
        laid down in this Article and allow for and contribute to audits, including inspections, conducted by the
        controller or another auditor mandated by the controller.
        <br />
      </StyledText>

      <StyledTitle>5. Term & Termination</StyledTitle>
      <StyledText>
        5.1. Agreement Term. This Agreement will begin on the Effective Date and continue until the later of the
        following: (1) the expiration or completion of all Subscription Periods under any open Order Schedules; (2) if
        the Order Schedule specifies a pay-as-you-go basis, the date you close your account; (3) the date on which the
        Agreement is terminated as specified herein. Each Subscription Period will automatically renew for successive
        one-year periods (or such longer time as may be specified on an Order Schedule) unless either party gives at
        least ninety (90) days written notice to the other party prior to the end of the then-current Subscription
        Period.
      </StyledText>
      <StyledText>
        5.2. Termination for Breach. Either party may terminate this Agreement if the other party breaches any material
        term of this Agreement and fails to cure that breach within 30 days after receipt of written notice.
      </StyledText>
      <StyledText>
        5.3. Termination by TigerGraph. TigerGraph may terminate this Agreement for any reason by providing Customer 30
        days’ advance notice.
      </StyledText>
      <StyledText>
        5.4. Effect of Termination. If the Agreement expires or is terminated, then: (1) the rights granted by one party
        to the other will immediately cease; (2) all Fees owed by Customer are immediately due upon receipt of the final
        invoice, and if Customer provided a credit card, Customer authorizes TigerGraph to charge for all outstanding
        Fees; and (3) upon request, each party will use commercially reasonable efforts to return or destroy all
        Confidential Information of the other party.
      </StyledText>

      <StyledTitle>6. Confidentiality</StyledTitle>
      <StyledText>
        6.1. Confidentiality Obligation. During the term of this Agreement, both parties agree that: (1) Confidential
        Information will be used only in accordance with the terms and conditions of this Agreement; (2) each will use
        the same degree of care it uses to protect its own confidential information, but in no event less than
        reasonable care; and (3) the Confidential Information may be disclosed only to (a) employees, agents and
        contractors with a need to know, and to its auditors and legal counsel, in each case, who are under a written
        obligation to keep such information confidential using standards of confidentiality not less restrictive than
        those required by this Agreement; and (b) comply with a legal requirement or process, including, but not limited
        to, civil and criminal subpoenas, court orders or other compulsory disclosures inside or outside of the United
        States. TigerGraph may use Customer Data for internal business purposes only, including providing Support, and
        improving, testing and providing the Cloud Services. “Confidential Information” means any information designated
        as confidential orally or in writing by either party, or any information that the receiving party knows, or has
        reason to know, is confidential or proprietary based upon its treatment by the disclosing party. Confidential
        Information does not include (a) information submitted by Customer via a support ticket or other type of support
        request; or (b) usage data derived by TigerGraph from Customer’s use of the Software and Cloud Services provided
        that such data is anonymized and aggregated.
      </StyledText>
      <StyledText>
        6.2. Exclusions. This Agreement imposes no obligation with respect to information that: (1) is a part of or
        enters into the public domain; (2) was already in the recipient’s possession prior to the date of disclosure
        other than by breach of this Agreement; (3) is rightfully received from a third party without any duty of
        confidentiality; or (4) is independently developed without reference to the Confidential Information of the
        disclosing party.
      </StyledText>
      <StyledText>
        6.3. Publicity. TigerGraph may identify Customer as a customer of the Cloud Services, provided that any such
        statements shall conform with any trademark use guidelines provided by Customer. Each party must obtain the
        other party’s advance consent to any press release relating to the Cloud Services.
      </StyledText>

      <StyledTitle>7. Warranties</StyledTitle>
      <StyledText>
        7.1. Services Warranty. TigerGraph warrants that the Cloud Services will meet the requirements set forth in the
        Documentation applicable to this Cloud Services ordered. If the Cloud Services fail to conform to the foregoing
        warranty, as Customer’s sole and exclusive remedy for such failure, and provided that Customer provides
        TigerGraph with written notice of such breach within thirty (30) days of occurrence, TigerGraph will promptly
        attempt to re-perform the applicable Cloud Services or at TigerGraph’s option terminate this Agreement and
        refund any pre-paid Fees attributable to the non-conforming Cloud Services following the termination date.
      </StyledText>
      <StyledText>
        7.2. SLA Warranty. TigerGraph warrants that the Cloud Services will meet the service levels as stated in the
        SLA. As Customer’s sole and exclusive remedy for any breach of the SLA, Customer shall be given the applicable
        Service Credit, as defined in the SLA.
      </StyledText>
      <StyledText>
        7.3. Authority. Each party warrants that it has full power and authority to enter into this Agreement.
      </StyledText>
      <StyledText>
        7.4. EXCEPT AS EXPRESSLY PROVIDED IN THIS SECTION 8, NEITHER TIGERGRAPH NOR ITS SUPPLIERS PROVIDE ANY OTHER
        WARRANTIES REGARDING THE SOFTWARE, THE CLOUD SERVICES OR THE PROFESSIONAL SERVICES, AND TO THE FULLEST EXTENT
        PERMITTED BY LAW TIGERGRAPH DISCLAIMS ALL OTHER WARRANTIES, TERMS AND CONDITIONS, EXPRESS OR IMPLIED, INCLUDING
        BUT NOT LIMITED TO ANY IMPLIED WARRANTIES AND CONDITIONS OF MERCHANTABILITY, QUALITY, FITNESS FOR A PARTICULAR
        PURPOSE, TITLE, AND NONINFRINGEMENT, AND ANY WARRANTIES, TERMS AND CONDITIONS ARISING OUT OF COURSE OF DEALING
        OR USAGE OF TRADE. NO ADVICE OR INFORMATION, WHETHER ORAL OR WRITTEN, OBTAINED FROM TIGERGRAPH OR ELSEWHERE WILL
        CREATE ANY WARRANTY, TERM OR CONDITION UNLESS EXPRESSLY STATED IN THIS AGREEMENT. THE SOFTWARE IS NOT DESIGNED,
        MANUFACTURED, OR INTENDED FOR HIGH RISK ACTIVITIES. THE CLOUD SERVICES, PROFESSIONAL SERVICES, SOFTWARE, AND
        CONSOLE ARE PROVIDED ON AN “AS IS” AND “AS AVAILABLE” BASIS. TIGERGRAPH EXPRESSLY DISCLAIMS ANY REPRESENTATIONS
        OR WARRANTIES THAT CUSTOMER’S USE OF THE CLOUD SERVICES OR SOFTWARE WILL SATISFY ANY STATUTORY OR REGULATORY
        OBLIGATIONS, OR WILL ASSIST WITH, GUARANTEE OR OTHERWISE ENSURE COMPLIANCE WITH ANY APPLICABLE LAWS OR
        REGULATIONS. CUSTOMER ASSUMES ALL RESPONSIBILITY FOR DETERMINING WHETHER THE CLOUD SERVICES, SOFTWARE OR THE
        INFORMATION GENERATED THEREBY IS ACCURATE OR SUFFICIENT FOR CUSTOMER’S PURPOSES.
      </StyledText>

      <StyledTitle>8. Indemnity</StyledTitle>
      <StyledText>
        8.1. Indemnity by TigerGraph. TigerGraph shall defend or settle at its sole expense any claim brought against
        Customer, its directors, officers, or employees by a third party alleging that the Software as installed by
        TigerGraph in the Cloud Services infringes or misappropriates any patent, copyright, or trade secret of a third
        party, and TigerGraph shall pay all damages finally awarded or costs of settlement of the claim provided that
        Customer: (1) provides TigerGraph prompt written notice of any claim; (2) gives TigerGraph sole control of the
        defense and settlement of the claim but may not settle any claim unless such settlement fully releases Customer
        from any liability; and (3) provides all reasonable assistance in connection with the claim.
      </StyledText>
      <StyledText>
        8.2. Injunctions. If Customer’s rights to use the Software are, or in TigerGraph’ opinion could be, enjoined due
        to an indemnified claim, then TigerGraph may, at its sole option and expense: (1) procure for Customer the right
        to continue using the Software according to the terms of this Agreement, (2) modify the Software such that it
        operates with materially equivalent functionality without infringing or misappropriation, or (3) if neither of
        the foregoing options is commercially reasonable, terminate the Agreement and refund any prepaid portion of the
        Fees attributable to the period following termination.
      </StyledText>
      <StyledText>
        8.3. Exclusions. The indemnity provided by TigerGraph under this Agreement does not extend to claims arising
        from or relating to: (1) modifications to the Software not provided by or approved in writing by TigerGraph; (2)
        use of the Software in combination with any data, software, or hardware not provided by TigerGraph to the extent
        the alleged infringement would not have occurred without the combination; (3) Third Party Software; (4) use of
        the Software in violation of this Agreement, or (5) allegedly infringing activities that continue after
        TigerGraph has informed Customer in writing of and made available to Customer at no additional charge a
        substantially similar version of the Software that would have avoided the alleged infringement.
      </StyledText>
      <StyledText>
        8.4. Remedy. This Section 6 states Customer’s sole and exclusive remedy with respect to claims of infringement
        of third party proprietary rights of any kind and is subject to the terms of the section titled “Limitation of
        Liability.”
      </StyledText>
      <StyledText>
        8.5. Customer Indemnity. Customer shall defend or settle at its sole expense any claim brought against
        TigerGraph, its directors, officers, or employees by a third party arising out of Customer’s misuse of the
        Software or the Cloud Services, including in breach of Section 5.4 (“Customer Data”).
      </StyledText>

      <StyledSubTitle>9. Limitation of Liability</StyledSubTitle>
      <StyledText>
        To the fullest extent permitted by applicable law, in no event shall TigerGraph or its suppliers be liable for
        damages other than direct damages, including the cost of procurement of substitute goods or technology, loss of
        profits, or for any special, consequential, incidental, punitive or indirect damages on any theory of liability,
        whether in statute, contract, tort, strict liability, indemnity or otherwise, even if advised of the possibility
        of such damages.
      </StyledText>
      <StyledText>
        To the fullest extent permitted by applicable law, in no event shall the total liability of TigerGraph to
        Customer under this Agreement exceed the greater of the total amounts paid by Customer to TigerGraph during the
        12 months prior to the date the claim arises, or ten thousand US dollars. The liability limitations in this
        paragraph (and otherwise in the Agreement) do not limit or exclude damages for bodily injury or death or other
        damages that under applicable law cannot lawfully be limited or excluded.
      </StyledText>

      <StyledTitle>10. Insurance</StyledTitle>
      <StyledText>
        During the term of this Agreement, TigerGraph will maintain at least the following levels of insurance coverage:
        (1) general commercial liability of $1 million per occurrence, $2 million aggregate for bodily injury and
        property damage; (2) automotive, hired and non-owned liability of $1 million for bodily injury and property
        damage; (3) errors and omissions of $2 million per claim and aggregate limit; and (4) workers’ compensation
        meeting statutory limits.
      </StyledText>

      <StyledTitle>11. Miscellaneous</StyledTitle>
      <StyledText>
        11.1. Affiliates. TigerGraph and Customer agree that Affiliates of Customer may acquire Cloud Services from
        TigerGraph or its Affiliates by entering an Order Schedule with TigerGraph (or a TigerGraph Affiliate) that
        incorporates the terms and conditions of this Agreement and sets forth the then-applicable pricing. The parties
        acknowledge that adjustments to the terms of this Agreement may be made in a particular Order Schedule (for
        example, to address disparate tax and/or legal regimes in other geographic regions). “Affiliate” means an entity
        that owns or controls, is owned or controlled by, or is under common control or ownership with a party, where
        “control” is the possession, direct or indirect, of the power to direct or cause the direction of the management
        and policies of an entity, whether through ownership of voting securities, by contract or otherwise.
      </StyledText>
      <StyledText>
        11.2. Assignment. The Agreement may not be assigned by either party by operation of law or otherwise, without
        the prior written consent of the other party, which consent will not be unreasonably withheld. Notwithstanding
        the foregoing, either party may assign this Agreement in its entirety (including all Order Schedules), without
        consent of the other party, to its Affiliate or in connection with a merger, acquisition, corporate
        reorganization, or the sale of all or substantially all of the assets of the business to which the Agreement
        relates. Any such assignment shall be effective upon payment of all amounts then due.
      </StyledText>
      <StyledText>
        11.3. Conflicting Terms. If there is a conflict among the documents that make up this Agreement, the documents
        will control in the following order: the Order Schedule, these Subscription Terms, and the terms located at any
        URL referenced in these Subscription Terms.
      </StyledText>
      <StyledText>
        11.4. Entire Agreement. This Agreement sets out all terms agreed between the parties and supersedes all other
        agreements between the parties relating to its subject matter. In entering into this Agreement, neither party
        has relied on, and neither party will have any right or remedy based on, any statement, representation or
        warranty (whether made negligently or innocently), except those expressly set out in this Agreement. The terms
        located at a URL referenced in this Agreement are hereby incorporated by this reference. After the Effective
        Date, TigerGraph may provide Customer with an updated URL in place of any URL in this Agreement. Customer agrees
        that its purchase of a Subscription is neither contingent upon the delivery of any future functionality or
        features nor dependent upon any oral or written public comments made by TigerGraph, including any roadmaps or
        target release dates, with respect to future functionality or features.
      </StyledText>
      <StyledText>
        11.5. Export Controls. Customer agrees to comply with all export and re-export restrictions and regulations of
        the Department of Commerce and any other United States or foreign agencies and authorities in connection with
        Customer’s use of the Cloud Services and Software. In particular, but without limitation, the Software may not,
        in violation of any laws, be exported or re-exported: (1) into any U.S. embargoed country; or (2) to anyone on
        the U.S. Treasury Department’s list of Specially Designated Nationals or the U.S. Commerce Department’s Table of
        Deny Orders. Customer represents and warrants that Customer and its Affiliates are not located in, under the
        control of, or a national or resident of any such country or on any such list.
      </StyledText>
      <StyledText>
        11.6. Force Majeure. Neither party will be liable for failure or delay in its performance under this Agreement
        to the extent caused by circumstances beyond its reasonable control. TigerGraph reserves the right to suspend
        Cloud Services to comply with laws.
      </StyledText>
      <StyledText>
        11.7. Governing Law. This Agreement is to be construed in accordance with and governed by the internal laws of
        the State of California without regard to its conflict of laws principles, and each party hereby consents to the
        jurisdiction of the federal or state courts of Santa Clara County, California, USA. Each party hereby waives any
        right to jury trial in any litigation in any way arising out of or related to this Agreement.
      </StyledText>
      <StyledText>
        11.8. Independent Contractors. This Agreement will not be construed as creating an agency, partnership, joint
        venture or any other form of association, for tax purposes or otherwise, between the parties; the parties will
        at all times be and remain independent contractors.
      </StyledText>
      <StyledText>
        11.9. No Third-Party Beneficiaries. This Agreement does not confer any benefits on any third party unless it
        expressly states that it does.
      </StyledText>
      <StyledText>
        11.10. Notices. All notices must be in writing and addressed to the other party’s legal department and primary
        point of contact. Notice will be treated as given on receipt, as verified by written or automated receipt or by
        electronic log (as applicable).
      </StyledText>
      <StyledText>
        11.11. Severability and Waiver. In the event that any provision of this Agreement (or any portion hereof) is
        determined by a court of competent jurisdiction to be illegal, invalid or otherwise unenforceable, the provision
        (or portion) will be enforced to the extent possible consistent with the stated intention of the parties, or, if
        incapable of enforcement, will be deemed to be severed and deleted from this Agreement, while the remainder of
        this Agreement will continue in full force. The waiver by either party of any default or breach of this
        Agreement will not constitute a waiver of any other or subsequent default or breach.
      </StyledText>
      <StyledText>
        11.12. Survival. The following sections will survive any expiration or termination of this Agreement: Payment,
        Restrictions, Proprietary Rights, Effect of Termination, Confidentiality, Limitation of Liability, and
        Miscellaneous.
      </StyledText>
      <StyledText>
        11.13. Updates. Except as expressly provided herein, no modification of this Agreement will be effective unless
        contained in writing and signed by an authorized representative of each party. TigerGraph may make changes to
        terms located at a URL referenced in this Agreement, including these Subscription Terms (collectively, the “URL
        Terms”) from time to time. TigerGraph will post the amended terms and will update the “Last Updated Date” at the
        top. By continuing to use the Cloud Services, Console, or Software after TigerGraph has provided Customer with
        such notice of a change, Customer is indicating that it agrees to be bound by the modified terms. If the change
        has a material adverse impact on Customer and Customer does not agree to the change, Customer must notify
        TigerGraph within 30 days of the applicable Last Updated Date. If Customer notifies TigerGraph as required, then
        Customer will remain governed by the terms in effect immediately prior to the change until the end of the
        then-current Subscription Period. If the Subscription Period is renewed, it will do so under the updated URL
        Terms.
      </StyledText>

      <Body2 $style={{ fontStyle: 'italic' }}>(updated Jan 20, 2025)</Body2>
    </InlineBlock>
  );
};
