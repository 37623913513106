import mediaQuery from '@/lib/breakpoints';
import { useStyletron } from '@tigergraph/app-ui-lib/Theme';
import { SVGProps } from 'react';

const Logo1 = (props: SVGProps<SVGSVGElement> & { branchColor: string }) => {
  const { branchColor, ...rest } = props;
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={36} height={26} fill="none" {...rest}>
      <path
        fill={branchColor}
        d="M27.89 10.088h.159l-.002-.005a7.933 7.933 0 0 1 5.471 2.17 7.79 7.79 0 0 1 2.43 5.32 7.777 7.777 0 0 1-1.966 5.505 7.92 7.92 0 0 1-5.267 2.62v.032h-6.117l.585-.24c4.77-1.986 9.31-4.954 6.232-10.436 0 0-.046-.077-.11-.07-.065.006-.046.113-.046.113.127 1.729-.872 4.47-4.842 7.039a.152.152 0 0 1-.138.023c-.07-.034 0-.127 0-.127 1.73-1.884 5.625-7.148 1.34-10.444 0 0-.103-.095-.195-.068-.091.027-.064.13-.064.13 1.011 2.684-.293 4.975-.96 5.942 0 0-.062.089-.127.08-.064-.008-.037-.134-.037-.134.917-3.857-.631-6.258-3.15-8.204l.607-2.056-1.856.021a5.962 5.962 0 0 0-2.747.921c-.656 0-1.309.088-1.941.261a6.695 6.695 0 0 0-2.735 1.702c-.017 0-3.375 3.726-3.375 3.726l1.684 2.277c-.103 2.242 1.835 3.024 2.084 3.124l.006.002c.5-1.124 2.116-2.48 3.788-2.424a3.737 3.737 0 0 1 1.592.404 3.7 3.7 0 0 1 1.26 1.045c.863-1.878-.179-4.027-.582-4.86l-.026-.052a.074.074 0 0 1 .023-.1c.058-.037.138.07.138.07 3.38 3.81 1.021 9.234-1.007 12.339H6.797A6.821 6.821 0 0 1 2.26 24.02a6.675 6.675 0 0 1-.983-8.932 6.789 6.789 0 0 1 4.059-2.642 5.356 5.356 0 0 1 .255-2.493c.28-.8.747-1.522 1.362-2.11a5.464 5.464 0 0 1 2.176-1.275 5.502 5.502 0 0 1 2.523-.165 8.266 8.266 0 0 1 3.749-4.95 8.401 8.401 0 0 1 6.167-.979 8.334 8.334 0 0 1 5.122 3.541 8.195 8.195 0 0 1 1.201 6.073Z"
      />
      <path
        fill={branchColor}
        d="M14.045 12.813c.175-.68.562-1.873.936-2.06.375-.188.75-.188 1.873-.188l-2.809 2.248Z"
      />
    </svg>
  );
};

const Logo2 = (props: SVGProps<SVGSVGElement> & { branchColor: string; grayColor: string }) => {
  const { branchColor, grayColor, ...rest } = props;
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="-2 -4 100 32" width={63} height={21} fill="none" {...rest}>
      <path d="M1.64648 2.95539H5.30257V12.4949H7.56871V2.95539H11.2248V1H1.64648V2.95539Z" fill={branchColor} />
      <path d="M14.6725 4.48083H12.4919V12.4169H14.6725V4.48083Z" fill={branchColor} />
      <path
        d="M36.5205 8.50581V12.4166H38.6953V8.50581C38.6953 6.90797 40.0168 5.60624 41.6464 5.59507V3.55029C38.818 3.56147 36.5205 5.73474 36.5205 8.50581Z"
        fill={branchColor}
      />
      <path
        d="M20.5747 3.50586C18.0688 3.50586 16.031 5.42773 16.031 7.79375C16.031 10.1598 18.0688 12.0816 20.5747 12.0816C21.4395 12.0816 22.2501 11.8526 22.9379 11.4559V11.6319C22.9379 12.333 22.701 12.8861 22.2244 13.2912C21.7477 13.6962 21.1084 13.9001 20.3036 13.9001C19.2847 13.9001 18.3514 13.5929 17.5009 12.9811L16.6275 14.5259C17.7891 15.3024 19.0535 15.6907 20.4177 15.6907C21.3567 15.6907 22.1816 15.5231 22.8922 15.1907C23.6029 14.8555 24.1509 14.3806 24.5362 13.7633C24.9215 13.1459 25.1156 12.4196 25.1156 11.5872V7.79933C25.1156 5.43611 23.0777 3.51145 20.5719 3.51145L20.5747 3.50586ZM20.5747 10.3274C19.2561 10.3274 18.0317 9.18487 18.0317 7.7714C18.0317 6.35793 19.2561 5.21543 20.5747 5.21543C21.8933 5.21543 23.1177 6.35793 23.1177 7.7714C23.1177 9.18487 21.8933 10.3274 20.5747 10.3274Z"
        fill={branchColor}
      />
      <path
        d="M13.5822 1C12.8972 1 12.3407 1.54472 12.3407 2.21513C12.3407 2.88555 12.8972 3.43027 13.5822 3.43027C14.2672 3.43027 14.8238 2.88555 14.8238 2.21513C14.8238 1.54472 14.2672 1 13.5822 1Z"
        fill={branchColor}
      />
      <path
        d="M30.9094 3.56702C30.0246 3.56702 29.2369 3.75697 28.5433 4.13408C27.8498 4.51119 27.3104 5.03914 26.9251 5.71794C26.5398 6.39674 26.3457 7.17331 26.3457 8.05044C26.3457 8.92757 26.5369 9.70135 26.9165 10.3746C27.2961 11.0478 27.8355 11.5701 28.5348 11.9417C29.234 12.3132 30.0417 12.5003 30.9579 12.5003C31.7399 12.5003 32.4534 12.3691 33.0956 12.1065C33.7378 11.8439 34.2829 11.4724 34.731 10.9891L33.558 9.79074C33.244 10.1092 32.8787 10.3522 32.4592 10.5226C32.0396 10.693 31.6001 10.7768 31.1434 10.7768C30.4841 10.7768 29.9276 10.598 29.4738 10.2433C29.02 9.88851 28.7203 9.39687 28.5776 8.77394H35.2048C35.2162 8.63147 35.2219 8.41917 35.2219 8.13425C35.2219 6.69005 34.8623 5.5671 34.1402 4.76818C33.4181 3.96927 32.3421 3.56981 30.9094 3.56981V3.56702ZM28.5719 7.11186C28.6946 6.56435 28.9458 6.12299 29.3254 5.79057C29.7563 5.41346 30.29 5.22351 30.9265 5.22351C31.6086 5.22351 32.1566 5.41626 32.5705 5.79895C32.9329 6.13416 33.1441 6.57273 33.2069 7.11186H28.5719Z"
        fill={branchColor}
      />

      <path
        d="M47.0729 3.11444C47.7099 2.76107 48.4114 2.58295 49.1687 2.58295C49.7793 2.58295 50.3839 2.70074 50.9886 2.93632C51.5933 3.1719 52.1246 3.50515 52.5854 3.93322L53.8975 2.26119C53.2869 1.73258 52.5619 1.31601 51.7224 1.01148C50.8829 0.706951 50.0317 0.554688 49.1687 0.554688C47.9623 0.554688 46.8586 0.821867 45.8636 1.35623C44.8685 1.89059 44.0848 2.61743 43.5095 3.53388C42.9341 4.45034 42.6465 5.47309 42.6465 6.59927C42.6465 7.72544 42.9283 8.75107 43.4919 9.68189C44.0554 10.6098 44.8245 11.3453 45.7961 11.8854C46.7676 12.4255 47.8449 12.6956 49.0308 12.6956C49.8585 12.6956 50.7039 12.5519 51.5669 12.2646C52.4298 11.9773 53.1783 11.6039 53.8094 11.1413V6.65098H51.7899V9.90885C51.3761 10.1444 50.9416 10.3283 50.4867 10.4576C50.0317 10.5869 49.5973 10.6529 49.1834 10.6529C48.4114 10.6529 47.7099 10.4719 47.0788 10.1128C46.4448 9.75371 45.9428 9.26244 45.57 8.64477C45.1973 8.0271 45.0094 7.34335 45.0094 6.60214C45.0094 5.86093 45.1943 5.19442 45.5612 4.57675C45.9281 3.95907 46.433 3.47068 47.07 3.11731L47.0729 3.11444Z"
        fill={grayColor}
      />
      <path
        d="M88.7337 4.34957C88.1231 3.72615 87.2895 3.413 86.2328 3.413C84.6448 3.43599 83.5353 4.03642 82.9042 5.22006V0H80.6616V12.5287H82.9042V8.08721C82.9042 7.33451 83.1214 6.71396 83.5588 6.22844C83.9961 5.7458 84.589 5.49585 85.3346 5.48436C85.9657 5.48436 86.4676 5.66823 86.8346 6.03309C87.2015 6.39794 87.3864 6.89783 87.3864 7.52699V12.5258H89.6466V6.88921C89.6466 5.82049 89.3413 4.97299 88.7308 4.3467L88.7337 4.34957Z"
        fill={grayColor}
      />
      <path
        d="M77.1245 4.10253C76.3936 3.72044 75.5512 3.52795 74.5972 3.52795C73.6433 3.52795 72.7803 3.72044 72.0435 4.10253C71.3068 4.48463 70.7344 5.02186 70.3264 5.71423C69.9184 6.4066 69.7129 7.20239 69.7129 8.10448C69.7129 8.11597 69.7129 8.12746 69.7129 8.13896V15.7148H71.9731V12.1036C71.9966 12.115 72.0171 12.1294 72.0435 12.1438C72.7803 12.5259 73.6315 12.7184 74.5972 12.7184C75.5629 12.7184 76.3936 12.5259 77.1245 12.1438C77.8554 11.7617 78.4249 11.2216 78.8329 10.5235C79.2409 9.82535 79.4463 9.02094 79.4463 8.10735C79.4463 7.19377 79.2409 6.40947 78.8329 5.7171C78.4249 5.02473 77.8554 4.4875 77.1245 4.10541V4.10253ZM76.4611 10.1155C75.9768 10.6211 75.3575 10.874 74.5972 10.874C73.837 10.874 73.1824 10.6211 72.6981 10.1155C72.2167 9.60988 71.9731 8.95199 71.9731 8.14183C71.9731 7.33167 72.2138 6.6709 72.6981 6.16527C73.1824 5.65964 73.8135 5.40683 74.5972 5.40683C75.3809 5.40683 75.9768 5.65964 76.4611 6.16527C76.9455 6.6709 77.1862 7.33167 77.1862 8.14183C77.1862 8.95199 76.9455 9.60988 76.4611 10.1184V10.1155Z"
        fill={grayColor}
      />
      <path
        d="M60.6369 3.41003C57.728 3.42153 55.3651 5.65664 55.3651 8.50656V12.5286H57.6018V8.50656C57.6018 6.86326 58.9609 5.52449 60.6369 5.513V3.41003Z"
        fill={grayColor}
      />
      <path
        d="M67.5025 4.25467C66.8421 3.69158 65.9116 3.41003 64.7169 3.41003C64.6171 3.41003 62.2748 3.41003 62.2748 3.41003V5.19985H64.2179C64.896 5.19985 65.4126 5.34637 65.7707 5.6394C66.1259 5.93244 66.3049 6.34326 66.3049 6.87188V7.10745H63.6133C62.4979 7.11895 61.6349 7.36314 61.0243 7.84292C60.6427 8.1417 60.3815 8.5123 60.2377 8.95473C60.1526 9.21903 60.1085 9.51207 60.1085 9.82809C60.1085 9.96024 60.1173 10.0895 60.132 10.2159C60.1408 10.2791 60.1496 10.3423 60.1643 10.4027C60.1878 10.5262 60.223 10.644 60.2641 10.7618C60.3257 10.937 60.4079 11.1037 60.5048 11.2645C60.7044 11.5863 60.9656 11.8535 61.2885 12.069C61.3413 12.1063 61.3971 12.1408 61.4558 12.1724C61.5145 12.2069 61.5732 12.2385 61.6349 12.2672C61.7141 12.3045 61.7934 12.339 61.8756 12.3735C62.3071 12.543 62.7914 12.632 63.3344 12.632H65.2834C65.9292 12.632 66.4928 12.5114 66.983 12.2701C67.4732 12.0287 67.8489 11.6926 68.1131 11.2645C68.3773 10.8365 68.5093 10.3596 68.5093 9.82809V6.61906C68.4976 5.60493 68.16 4.81775 67.4996 4.25467H67.5025ZM66.302 9.26787C66.302 9.26787 66.302 9.32246 66.302 9.69306C66.302 10.0637 66.1552 10.3797 65.8617 10.6038C65.5682 10.8279 65.169 10.9428 64.6641 10.9428H63.8716C63.6808 10.9428 63.5076 10.9284 63.3462 10.8939C63.079 10.8422 62.856 10.7445 62.671 10.6038C62.5977 10.5463 62.5331 10.486 62.4773 10.4199C62.4509 10.3854 62.4245 10.3538 62.401 10.3165C62.2865 10.1384 62.2278 9.93151 62.2278 9.69019C62.2278 9.59251 62.2366 9.50058 62.2542 9.41439C62.2894 9.24489 62.3599 9.10412 62.4656 8.9892C62.492 8.96047 62.5213 8.93462 62.5507 8.90876C62.58 8.88291 62.6153 8.85992 62.6505 8.83694C62.7209 8.79385 62.8002 8.7565 62.8912 8.72202C62.9352 8.70479 62.9851 8.69042 63.035 8.67606C63.2845 8.60711 63.5986 8.57263 63.9714 8.57263H66.2991V9.26213L66.302 9.26787Z"
        fill={grayColor}
      />
      <path
        d="M5.78563 27C5.03115 27 4.33851 26.9169 3.70771 26.7507C3.07691 26.5734 2.50796 26.313 2.00084 25.9695C1.49373 25.626 1.04228 25.2105 0.646484 24.723L2.22348 23.1274C2.82954 23.8809 3.42323 24.4017 4.00455 24.6898C4.58588 24.9778 5.24141 25.1219 5.97116 25.1219C6.39169 25.1219 6.77512 25.0665 7.12144 24.9557C7.46776 24.8338 7.73987 24.6731 7.93776 24.4737C8.13566 24.2632 8.23461 24.0249 8.23461 23.759C8.23461 23.5706 8.19132 23.3989 8.10474 23.2438C8.03053 23.0776 7.91303 22.9335 7.75224 22.8116C7.59144 22.6787 7.39355 22.5568 7.15854 22.446C6.92354 22.3352 6.65762 22.241 6.36077 22.1634C6.06392 22.0859 5.73616 22.0139 5.37747 21.9474C4.6972 21.8255 4.1035 21.6648 3.59639 21.4654C3.08928 21.2548 2.66256 21 2.31624 20.7008C1.96992 20.3906 1.71637 20.0471 1.55558 19.6704C1.39478 19.2825 1.31439 18.8449 1.31439 18.3573C1.31439 17.8698 1.43189 17.4211 1.66689 17.0111C1.91426 16.6011 2.24822 16.2465 2.66875 15.9474C3.08928 15.6482 3.57784 15.4155 4.13443 15.2493C4.69101 15.0831 5.29089 15 5.93405 15C6.6638 15 7.31315 15.0776 7.88211 15.2327C8.46343 15.3878 8.97054 15.6205 9.40344 15.9307C9.84871 16.2299 10.2136 16.5956 10.4981 17.0277L8.90251 18.4404C8.65514 18.097 8.37685 17.8144 8.06763 17.5928C7.75842 17.3601 7.42447 17.1884 7.06578 17.0776C6.70709 16.9557 6.32985 16.8947 5.93405 16.8947C5.48878 16.8947 5.09917 16.9501 4.76522 17.0609C4.44364 17.1717 4.19008 17.3324 4.00455 17.5429C3.81903 17.7424 3.72626 17.9861 3.72626 18.2742C3.72626 18.4958 3.78192 18.6953 3.89324 18.8726C4.00455 19.0388 4.15916 19.1884 4.35706 19.3213C4.56733 19.4543 4.83325 19.5706 5.15483 19.6704C5.47642 19.7701 5.84129 19.8587 6.24945 19.9363C6.92972 20.0582 7.54197 20.2244 8.08619 20.4349C8.6304 20.6343 9.09423 20.8781 9.47765 21.1662C9.86108 21.4432 10.1517 21.7645 10.3496 22.1302C10.5475 22.4848 10.6465 22.8837 10.6465 23.3269C10.6465 24.0914 10.4424 24.7507 10.0342 25.3047C9.63844 25.8476 9.07567 26.2687 8.34593 26.5679C7.61618 26.856 6.76275 27 5.78563 27Z"
        fill={grayColor}
      />
      <path
        d="M18.1877 18.8243C17.5588 18.2748 16.6727 18 15.535 18C15.44 18 13.2094 18 13.2094 18V19.7467H15.0598C15.7055 19.7467 16.1975 19.8897 16.5385 20.1757C16.8767 20.4617 17.0473 20.8626 17.0473 21.3785V21.6084H14.484C13.4218 21.6196 12.6 21.8579 12.0186 22.3262C11.6552 22.6178 11.4064 22.9794 11.2695 23.4112C11.1884 23.6692 11.1465 23.9551 11.1465 24.2636C11.1465 24.3925 11.1549 24.5187 11.1688 24.6421C11.1772 24.7037 11.1856 24.7654 11.1996 24.8243C11.222 24.9449 11.2555 25.0598 11.2946 25.1748C11.3533 25.3458 11.4316 25.5084 11.5238 25.6654C11.7139 25.9794 11.9627 26.2402 12.2702 26.4505C12.3205 26.4869 12.3736 26.5206 12.4295 26.5514C12.4854 26.585 12.5413 26.6159 12.6 26.6439C12.6755 26.6804 12.751 26.714 12.8292 26.7477C13.2401 26.9131 13.7013 27 14.2185 27H16.0745C16.6895 27 17.2261 26.8822 17.693 26.6467C18.1598 26.4112 18.5176 26.0832 18.7691 25.6654C19.0207 25.2477 19.1465 24.7822 19.1465 24.2636V21.1318C19.1353 20.1421 18.8138 19.3738 18.1849 18.8243H18.1877ZM17.0445 23.7168C17.0445 23.7168 17.0445 23.7701 17.0445 24.1318C17.0445 24.4935 16.9047 24.8019 16.6252 25.0206C16.3456 25.2393 15.9655 25.3514 15.4847 25.3514H14.73C14.5483 25.3514 14.3834 25.3374 14.2296 25.3037C13.9753 25.2533 13.7628 25.1579 13.5867 25.0206C13.5169 24.9645 13.4554 24.9056 13.4022 24.8411C13.3771 24.8075 13.3519 24.7766 13.3296 24.7402C13.2206 24.5664 13.1647 24.3645 13.1647 24.129C13.1647 24.0336 13.173 23.9439 13.1898 23.8598C13.2234 23.6944 13.2904 23.557 13.3911 23.4449C13.4162 23.4168 13.4442 23.3916 13.4721 23.3664C13.5001 23.3411 13.5336 23.3187 13.5672 23.2963C13.6343 23.2542 13.7097 23.2178 13.7964 23.1841C13.8383 23.1673 13.8858 23.1533 13.9333 23.1393C14.1709 23.072 14.47 23.0383 14.825 23.0383H17.0417V23.7112L17.0445 23.7168Z"
        fill={grayColor}
      />
      <path
        d="M34.6877 18.8243C34.0588 18.2748 33.1727 18 32.035 18C31.94 18 29.7094 18 29.7094 18V19.7467H31.5598C32.2055 19.7467 32.6975 19.8897 33.0385 20.1757C33.3767 20.4617 33.5473 20.8626 33.5473 21.3785V21.6084H30.984C29.9218 21.6196 29.1 21.8579 28.5186 22.3262C28.1552 22.6178 27.9064 22.9794 27.7695 23.4112C27.6884 23.6692 27.6465 23.9551 27.6465 24.2636C27.6465 24.3925 27.6549 24.5187 27.6688 24.6421C27.6772 24.7037 27.6856 24.7654 27.6996 24.8243C27.722 24.9449 27.7555 25.0598 27.7946 25.1748C27.8533 25.3458 27.9316 25.5084 28.0238 25.6654C28.2139 25.9794 28.4627 26.2402 28.7702 26.4505C28.8205 26.4869 28.8736 26.5206 28.9295 26.5514C28.9854 26.585 29.0413 26.6159 29.1 26.6439C29.1755 26.6804 29.251 26.714 29.3292 26.7477C29.7401 26.9131 30.2013 27 30.7185 27H32.5745C33.1895 27 33.7261 26.8822 34.193 26.6467C34.6598 26.4112 35.0176 26.0832 35.2691 25.6654C35.5207 25.2477 35.6465 24.7822 35.6465 24.2636V21.1318C35.6353 20.1421 35.3138 19.3738 34.6849 18.8243H34.6877ZM33.5445 23.7168C33.5445 23.7168 33.5445 23.7701 33.5445 24.1318C33.5445 24.4935 33.4047 24.8019 33.1252 25.0206C32.8456 25.2393 32.4655 25.3514 31.9847 25.3514H31.23C31.0483 25.3514 30.8834 25.3374 30.7296 25.3037C30.4753 25.2533 30.2628 25.1579 30.0867 25.0206C30.0169 24.9645 29.9554 24.9056 29.9022 24.8411C29.8771 24.8075 29.8519 24.7766 29.8296 24.7402C29.7206 24.5664 29.6647 24.3645 29.6647 24.129C29.6647 24.0336 29.673 23.9439 29.6898 23.8598C29.7234 23.6944 29.7904 23.557 29.8911 23.4449C29.9162 23.4168 29.9442 23.3916 29.9721 23.3664C30.0001 23.3411 30.0336 23.3187 30.0672 23.2963C30.1343 23.2542 30.2097 23.2178 30.2964 23.1841C30.3383 23.1673 30.3858 23.1533 30.4333 23.1393C30.6709 23.072 30.97 23.0383 31.325 23.0383H33.5417V23.7112L33.5445 23.7168Z"
        fill={grayColor}
      />
      <path
        d="M63.6877 18.8243C63.0588 18.2748 62.1727 18 61.035 18C60.94 18 58.7094 18 58.7094 18V19.7467H60.5598C61.2055 19.7467 61.6975 19.8897 62.0385 20.1757C62.3767 20.4617 62.5473 20.8626 62.5473 21.3785V21.6084H59.984C58.9218 21.6196 58.1 21.8579 57.5186 22.3262C57.1552 22.6178 56.9064 22.9794 56.7695 23.4112C56.6884 23.6692 56.6465 23.9551 56.6465 24.2636C56.6465 24.3925 56.6549 24.5187 56.6688 24.6421C56.6772 24.7037 56.6856 24.7654 56.6996 24.8243C56.722 24.9449 56.7555 25.0598 56.7946 25.1748C56.8533 25.3458 56.9316 25.5084 57.0238 25.6654C57.2139 25.9794 57.4627 26.2402 57.7702 26.4505C57.8205 26.4869 57.8736 26.5206 57.9295 26.5514C57.9854 26.585 58.0413 26.6159 58.1 26.6439C58.1755 26.6804 58.251 26.714 58.3292 26.7477C58.7401 26.9131 59.2013 27 59.7185 27H61.5745C62.1895 27 62.7261 26.8822 63.193 26.6467C63.6598 26.4112 64.0176 26.0832 64.2691 25.6654C64.5207 25.2477 64.6465 24.7822 64.6465 24.2636V21.1318C64.6353 20.1421 64.3138 19.3738 63.6849 18.8243H63.6877ZM62.5445 23.7168C62.5445 23.7168 62.5445 23.7701 62.5445 24.1318C62.5445 24.4935 62.4047 24.8019 62.1252 25.0206C61.8456 25.2393 61.4655 25.3514 60.9847 25.3514H60.23C60.0483 25.3514 59.8834 25.3374 59.7296 25.3037C59.4753 25.2533 59.2628 25.1579 59.0867 25.0206C59.0169 24.9645 58.9554 24.9056 58.9022 24.8411C58.8771 24.8075 58.8519 24.7766 58.8296 24.7402C58.7206 24.5664 58.6647 24.3645 58.6647 24.129C58.6647 24.0336 58.673 23.9439 58.6898 23.8598C58.7234 23.6944 58.7904 23.557 58.8911 23.4449C58.9162 23.4168 58.9442 23.3916 58.9721 23.3664C59.0001 23.3411 59.0336 23.3187 59.0672 23.2963C59.1343 23.2542 59.2097 23.2178 59.2964 23.1841C59.3383 23.1673 59.3858 23.1533 59.4333 23.1393C59.6709 23.072 59.97 23.0383 60.325 23.0383H62.5417V23.7112L62.5445 23.7168Z"
        fill={grayColor}
      />
      <path d="M22.6465 27L19.5 18H21.5L23.6465 24.5L26 18H28L24.6465 27H23.6465H22.6465Z" fill={grayColor} />
      <path
        d="M44.5191 19.0504C43.7726 18.3525 42.8066 18 41.644 18C40.8928 18 40.2258 18.1461 39.6465 18.4378V18H37.6465V27H39.6465C39.6465 27 39.5586 21.7427 39.6465 21.3496C39.7344 20.9565 39.8983 20.6251 40.1358 20.3597C40.504 19.952 41.0134 19.7458 41.6465 19.7458C42.2795 19.7458 42.789 19.952 43.1572 20.3597C43.533 20.777 43.7221 21.3573 43.7221 22.0863V27H45.6465V22.024C45.6465 20.7482 45.2657 19.7458 44.5191 19.0504Z"
        fill={grayColor}
      />
      <path
        d="M54.5191 19.0504C53.7726 18.3525 52.8066 18 51.644 18C50.8928 18 50.2258 18.1461 49.6465 18.4378V18H47.6465V27H49.6465C49.6465 27 49.5586 21.7427 49.6465 21.3496C49.7344 20.9565 49.8983 20.6251 50.1358 20.3597C50.504 19.952 51.0134 19.7458 51.6465 19.7458C52.2795 19.7458 52.789 19.952 53.1572 20.3597C53.533 20.777 53.7221 21.3573 53.7221 22.0863V27H55.6465V22.024C55.6465 20.7482 55.2657 19.7458 54.5191 19.0504Z"
        fill={grayColor}
      />
    </svg>
  );
};

export default function Logo() {
  const [css, theme] = useStyletron();
  return (
    <span
      className={css({
        marginRight: '8px',
        display: 'flex',
        alignItems: 'end',
      })}
    >
      <Logo1 branchColor={theme.colors['background.brand']} />
      <Logo2
        className={css({
          [mediaQuery.medium]: {
            display: 'none',
          },
          [mediaQuery.small]: {
            display: 'none',
          },
        })}
        branchColor={theme.colors['background.brand']}
        grayColor={theme.colors['background.accent.gray.bolder']}
      />
    </span>
  );
}
