import { Tag } from '@tigergraph/app-ui-lib/tag';
import { FC } from 'react';
import { expand } from 'inline-style-expand-shorthand';
import { MetaAddonsType } from '@/hooks/useAddonsMeta';
import { StatefulTipsPopover } from '@/components/tipsPopover';
import { PLACEMENT, TRIGGER_TYPE } from 'baseui/popover';
import IconButton from '@/components/IconButton';
import { Info } from 'lucide-react';
import { useStyletron } from '@tigergraph/app-ui-lib/Theme';
import { cn } from '@/lib/utils';

const AddonsDrawerCommonHeader: FC<MetaAddonsType> = ({ Avatar, Title, Categories, Amount }) => {
  const [css, theme] = useStyletron();
  return (
    <>
      <div className="flex space-x-4 font-semibold">
        <div
          className={cn(
            'h-[56px] w-[56px] border rounded-sm p-1',
            css({
              backgroundColor: theme.colors['background.alternative'],
              borderColor: theme.colors['border.tertiary'],
            })
          )}
        >
          <img className="h-full w-full object-contain" src={Avatar} />
        </div>
        <div className="flex flex-col flex-grow justify-between">
          <h3 className="flex justify-between items-center">
            <span className="text-base">{Title}</span>
            {Amount && (
              <span
                className={cn(
                  'flex text-base font-bold capitalize',
                  css({
                    color: theme.colors['text.secondary'],
                  })
                )}
              >
                <span className="mr-2">{Amount}</span>
                <StatefulTipsPopover
                  content={
                    <div
                      className={css({
                        display: 'flex',
                        flexDirection: 'column',
                        gap: '12px',
                      })}
                    >
                      <span className="mr-2 w-[256px]">
                        Please note that enabling add-ons on your workspace may incur additional charges. The cost will
                        depend on the size of your workspace and its uptime. Keep in mind that different add-ons may
                        have varying charge percentages. Be sure to review the{' '}
                        <a
                          href="https://docs.tigergraph.com/cloud4/main/overview/pricing"
                          target="_blank"
                          style={{ color: theme.colors.secondary900, fontWeight: 900 }}
                          rel="noreferrer"
                        >
                          pricing
                        </a>{' '}
                        and consider the potential impact on your billing before enabling any add-ons for your
                        organization.
                      </span>
                    </div>
                  }
                  triggerType={TRIGGER_TYPE.hover}
                  placement={PLACEMENT.right}
                >
                  <IconButton
                    className={css({
                      marginRight: 'auto',
                    })}
                  >
                    <Info size={16} />
                  </IconButton>
                </StatefulTipsPopover>
              </span>
            )}
          </h3>
          <div className="space-x-3">
            {Categories?.map((category) => (
              <Tag
                closeable={false}
                key={category}
                overrides={{
                  Root: {
                    style: {
                      ...expand({
                        margin: 0,
                      }),
                    },
                  },
                }}
              >
                {category}
              </Tag>
            ))}
          </div>
        </div>
      </div>
    </>
  );
};

export default AddonsDrawerCommonHeader;
