import { Slider } from '@/components/ui/slider';
import { WorkspaceT } from '@/pages/workgroup/type';
import { Checkbox } from '@tigergraph/app-ui-lib/checkbox';
import { Input } from '@tigergraph/app-ui-lib/input';
import { Controller, useForm } from 'react-hook-form';
import { StyledForm, StyledFormAction, StyledFormBody } from '@/pages/workgroup/form/setting/styled';
import { Button } from '@tigergraph/app-ui-lib/button';
import { useEffect, useMemo, useState } from 'react';
import { useListAlertRules } from '@/hooks/useListAlertRules';
import {
  useMutateCreateAlertRule,
  useMutateDeleteAlertRule,
  useMutateUpdateAlertRule,
} from '@/hooks/useMutateAlertRules';
import { Result } from '@/lib/type';
import { LoadingIndicator } from '@/components/loading-indicator';
import toast from 'react-hot-toast';
import { ErrorDisplay } from '@/components/error';
import TooltipLabel from '@/components/TooltipLabel';
import { FormControl } from '@tigergraph/app-ui-lib/form-control';
import { Desc } from '@/pages/workgroup/form/StyledComponent';
import useProfile from '@/lib/useProfile';
import { Select } from '@tigergraph/app-ui-lib/select';
import {
  getGroupAdmins,
  getOrgAdmins,
  getSpaceAdmins,
  getSpaceUsers,
  useQueryListUsers,
} from '@/pages/admin/user/hook';
import { Option, Value } from 'baseui/select';
import { UserInfo } from '@/lib/models';
import { getUserFullname } from '@/utils/utils';
import { expand } from 'inline-style-expand-shorthand';
import { Label } from '@tigergraph/app-ui-lib/typography';
import { useStyletron } from '@tigergraph/app-ui-lib/Theme';

type AlertsProps = {
  workspace: WorkspaceT;
  onClose?: () => void;
};

type AlertFormData = {
  enable_cpu_limit: boolean;
  tigergraph_cpu_usage: string;
  enable_memory_limit: boolean;
  tigergraph_memory_usage_percent: string;
  recipientList: string[];
};

const percentValidate = (value: string | number) => {
  const val = Number(value);
  if (val > 0 && val <= 100) {
    return;
  }
  return 'invalid value';
};

export default function Alerts({ workspace, onClose }: AlertsProps) {
  const { data: profile } = useProfile();
  const [css, theme] = useStyletron();
  const {
    control,
    watch,
    handleSubmit,
    setValue,
    getValues,
    reset,
    formState: { errors, isDirty },
  } = useForm<AlertFormData>({
    defaultValues: {
      enable_cpu_limit: false,
      enable_memory_limit: false,
      tigergraph_cpu_usage: '80',
      tigergraph_memory_usage_percent: '60',
      recipientList: [profile.email],
    },
  });
  const {
    data: rules,
    isFetching: isLoadingRules,
    refetch: refreshAlertRules,
  } = useListAlertRules({
    workspaceID: workspace.workspace_id,
    workgroupID: workspace.workgroup_id,
  });
  const [error, setError] = useState<Error>();
  const cpuAlertRule = rules?.find((i) => i.metric === 'tigergraph_cpu_usage');
  const memAlertRule = rules?.find((i) => i.metric === 'tigergraph_memory_usage_percent');

  const { mutateAsync: createRule, isLoading: isCreatingRule } = useMutateCreateAlertRule();
  const { mutateAsync: updateRule, isLoading: isUpdatingRule } = useMutateUpdateAlertRule();
  const { mutateAsync: deleteRule, isLoading: isDeletingRule } = useMutateDeleteAlertRule();

  const enableCPULimit = watch('enable_cpu_limit');
  const enableMemoryLimit = watch('enable_memory_limit');
  const recipientList = watch('recipientList');

  const handleSave = async (data: AlertFormData) => {
    const ruleItems = [
      { metricName: 'tigergraph_cpu_usage', enable: data.enable_cpu_limit },
      { metricName: 'tigergraph_memory_usage_percent', enable: data.enable_memory_limit },
    ] as const;
    const commonParams = { workgroupID: workspace.workgroup_id, workspaceID: workspace.workspace_id, recipientList };

    const promiseGroup: Promise<Result>[] = [];
    for (const ruleItem of ruleItems) {
      const alertRule = rules?.find((i) => i.metric === ruleItem.metricName);
      const threshold = Number(data[ruleItem.metricName]);
      if (alertRule) {
        if (ruleItem.enable) {
          // update
          promiseGroup.push(
            updateRule({
              ...commonParams,
              ruleID: alertRule.id,
              threshold,
            })
          );
        } else {
          // delete
          promiseGroup.push(
            deleteRule({
              ...commonParams,
              ruleID: alertRule.id,
            })
          );
        }
      } else if (ruleItem.enable) {
        // create
        promiseGroup.push(
          createRule({
            ...commonParams,
            metric: ruleItem.metricName,
            threshold,
          })
        );
      }
    }

    const loadingToastID = toast.loading('Applying workspace alert rules...');
    try {
      await Promise.all(promiseGroup);
      await refreshAlertRules();
      toast.success('Successfully apply workspace alert rules.');
    } catch (e) {
      setError(e instanceof Error ? e : new Error('Internal Error'));
    } finally {
      toast.dismiss(loadingToastID);
    }
  };

  useEffect(() => {
    if (cpuAlertRule) {
      setValue('enable_cpu_limit', true);
      setValue('tigergraph_cpu_usage', String(cpuAlertRule.threshold));
      setValue('recipientList', cpuAlertRule.recipientList);
    } else {
      setValue('enable_cpu_limit', false);
      setValue('tigergraph_cpu_usage', '80');
    }

    if (memAlertRule) {
      setValue('enable_memory_limit', true);
      setValue('tigergraph_memory_usage_percent', String(memAlertRule.threshold));
      setValue('recipientList', memAlertRule.recipientList);
    } else {
      setValue('enable_memory_limit', false);
      setValue('tigergraph_memory_usage_percent', '60');
    }
    const values = getValues();
    reset(values);
  }, [cpuAlertRule, memAlertRule, setValue, reset, getValues]);

  const { isLoading, data: allUser = [] } = useQueryListUsers();

  const users = useMemo(() => {
    return [
      ...getOrgAdmins(allUser),
      ...getGroupAdmins(allUser, workspace.workgroup_id),
      ...getSpaceAdmins(allUser, workspace.workgroup_id, workspace.workspace_id),
      ...getSpaceUsers(allUser, workspace.workgroup_id, workspace.workspace_id),
    ];
  }, [allUser, workspace.workgroup_id, workspace.workspace_id]);

  return (
    <StyledForm>
      <StyledFormBody>
        <ErrorDisplay error={error} />
        {isLoadingRules ? (
          <LoadingIndicator />
        ) : (
          <ul className="alert-rules-wrapper">
            <li>
              <Desc
                $style={{
                  marginTop: '6px',
                  marginBottom: '12px',
                }}
              >
                Set up CPU and memory usage alerts to receive email notifications when thresholds are exceeded.
              </Desc>
            </li>
            <li>
              <div className="flex items-center justify-between">
                <TooltipLabel
                  label="CPU Alert"
                  tooltip="Set the CPU usage threshold percentage at which you want to receive an alert. For example, setting this to 80% means you will be alerted when CPU usage reaches 80%."
                />
                <Controller
                  control={control}
                  name="enable_cpu_limit"
                  render={({ field: { value, ...field } }) => (
                    <Checkbox checked={value} {...field} checkmarkType="toggle" />
                  )}
                />
              </div>
              {enableCPULimit && (
                <FormControl error={errors.tigergraph_cpu_usage?.message}>
                  <div className="flex items-center space-x-2">
                    <div className="flex-1 space-y-1 pb-6">
                      <Controller
                        control={control}
                        name="tigergraph_cpu_usage"
                        render={({ field: { value, onChange, ...field } }) => (
                          <Slider
                            {...field}
                            value={[Number(value)]}
                            onValueChange={(value) => onChange(value?.[0])}
                            step={1}
                            min={1}
                            max={100}
                          />
                        )}
                      />
                    </div>
                    <Controller
                      control={control}
                      name="tigergraph_cpu_usage"
                      rules={{
                        validate: percentValidate,
                      }}
                      render={({ field }) => (
                        <Input
                          {...field}
                          type="number"
                          overrides={{ Root: { style: { width: '70px' } } }}
                          endEnhancer="%"
                        />
                      )}
                    />
                  </div>
                </FormControl>
              )}
            </li>
            <li>
              <div className="flex items-center justify-between">
                <TooltipLabel
                  label="Memory Alert"
                  tooltip="Set the memory usage threshold percentage at which you want to receive an alert. For example, setting this to 75% means you will be alerted when memory usage reaches 75%."
                />
                <Controller
                  control={control}
                  name="enable_memory_limit"
                  render={({ field: { value, ...field } }) => (
                    <Checkbox checked={value} {...field} checkmarkType="toggle" />
                  )}
                />
              </div>
              {enableMemoryLimit && (
                <FormControl error={errors.tigergraph_memory_usage_percent?.message}>
                  <div className="flex items-center space-x-2">
                    <div className="flex-1 space-y-1 pb-6">
                      <Controller
                        control={control}
                        name="tigergraph_memory_usage_percent"
                        render={({ field: { value, onChange, ...field } }) => (
                          <Slider
                            {...field}
                            value={[Number(value)]}
                            onValueChange={(value) => onChange(value?.[0])}
                            min={1}
                            max={100}
                          />
                        )}
                      />
                    </div>
                    <Controller
                      control={control}
                      name="tigergraph_memory_usage_percent"
                      rules={{
                        validate: percentValidate,
                      }}
                      render={({ field }) => (
                        <Input
                          {...field}
                          max={100}
                          min={1}
                          type="number"
                          overrides={{ Root: { style: { width: '70px' } } }}
                          endEnhancer="%"
                        />
                      )}
                    />
                  </div>
                </FormControl>
              )}
            </li>
            {enableCPULimit || enableMemoryLimit ? (
              <li>
                <div className="flex items-center justify-between">
                  <TooltipLabel
                    label="Recipient(s)"
                    tooltip="Specify the email addresses that should receive alert notifications.  You can add multiple recipients by entering their email addresses here."
                  />
                </div>
                <FormControl>
                  <Controller
                    control={control}
                    name="recipientList"
                    render={({ field: { value, onChange, ref, ...field } }) => {
                      return (
                        <Select
                          multi
                          creatable
                          isLoading={isLoading}
                          placeholder="Search user"
                          options={users.map((u) => ({
                            ...u,
                            label: u.email === profile.email ? `${u.email} (You)` : u.email,
                          }))}
                          value={
                            isLoading
                              ? []
                              : recipientList.map((email) => ({
                                  email,
                                }))
                          }
                          maxDropdownHeight={'400px'}
                          valueKey="email"
                          filterOptions={filterOptions}
                          getValueLabel={({ option }) => {
                            return option.email === profile.email ? `${option.email} (You)` : option.email;
                          }}
                          onChange={(params) => {
                            onChange(params.value.map((i) => i.email));
                          }}
                          inputRef={ref}
                          {...field}
                          overrides={{
                            Tag: {
                              props: {
                                overrides: {
                                  Root: {
                                    style: {
                                      ...expand({
                                        margin: '2px',
                                      }),
                                    },
                                  },
                                  Text: {
                                    style: {
                                      maxWidth: '100%',
                                    },
                                  },
                                },
                              },
                            },
                          }}
                        />
                      );
                    }}
                  />
                </FormControl>
                <Label
                  className={css({
                    color: theme.colors['text.secondary'],
                    marginTop: '-8px',
                  })}
                >
                  If no email addresses are added here, alerts will default to the workspace creator’s email.
                </Label>
              </li>
            ) : null}
          </ul>
        )}
      </StyledFormBody>
      <StyledFormAction>
        <Button type="button" kind="secondary" size="large" onClick={() => onClose?.()}>
          Cancel
        </Button>
        <Button
          type="button"
          size="large"
          onClick={handleSubmit(handleSave)}
          disabled={!isDirty}
          isLoading={isCreatingRule || isDeletingRule || isUpdatingRule}
        >
          Save
        </Button>
      </StyledFormAction>
    </StyledForm>
  );
}

function filterOptions(options: Value, filterValue: string) {
  let ret: Option[] = [];

  // support create new option
  if (filterValue) {
    ret.push({
      label: filterValue,
      email: filterValue,
      disabled: filterValue.length < 3 || !filterValue.includes('@'),
    });
  }
  for (let option of options) {
    const u = option as UserInfo;
    let displayName = getUserFullname(u);
    if (
      u.email?.toLowerCase().includes(filterValue.toLowerCase()) ||
      displayName.toLowerCase().includes(filterValue.toLowerCase())
    ) {
      ret.push(u);
    }
  }
  return ret;
}
