import { useRef } from 'react';
import { SchemaDesignerLogicService, SchemaChangeLogicService } from '@tigergraph/tools-models/services';
import { getUDTTuples, History, LoadingJobData } from '@tigergraph/tools-models';
import { WorkspaceT } from '@/pages/workgroup/type';
import { useQuery } from 'react-query';
import { ID_TOKEN_KEY } from '@/contexts/workspaceContext';

export const useSchemaDesignerLogicService = () => {
  const schemaDesignerLogicServiceRef = useRef<SchemaDesignerLogicService>();
  if (!schemaDesignerLogicServiceRef.current) {
    schemaDesignerLogicServiceRef.current = new SchemaDesignerLogicService();
  }
  return schemaDesignerLogicServiceRef.current;
};

export const useSchemaChangeLogicService = () => {
  const schemaChangeLogicServiceRef = useRef<SchemaChangeLogicService>();
  if (!schemaChangeLogicServiceRef.current) {
    schemaChangeLogicServiceRef.current = new SchemaChangeLogicService();
  }
  return schemaChangeLogicServiceRef.current;
};

export const useLoadingJobsHistory = () => {
  const loadingJobsHistoryRef = useRef<History<{ [key: string]: LoadingJobData }>>();
  if (!loadingJobsHistoryRef.current) {
    loadingJobsHistoryRef.current = new History<{ [key: string]: LoadingJobData }>();
  }
  return loadingJobsHistoryRef.current;
};

export const useUdtList = (currentGraph: string, currentWorkspace?: WorkspaceT) => {
  const { data } = useQuery(['getUdtList', currentGraph], async () => {
    if (!currentWorkspace) {
      return [];
    }
    const response = await getUDTTuples(
      { graph: currentGraph },
      {
        baseURL: `https://${currentWorkspace.nginx_host}`,
        version: currentWorkspace.tg_version,
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem(ID_TOKEN_KEY)}`,
        },
      }
    );
    return response.data.results as any;
  });
  return { data };
};
