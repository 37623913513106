import { axiosController } from '@/lib/network';

export type OnboardingTask = {
  id: string;
  task_name: OnboardingTaskName;
  description: string;
  completed: boolean;
  credits: number;
};

export enum OnboardingTaskName {
  signup = 'Sign up for TigerGraph Savanna',
  createWorkspace = 'Create a workspace',
  inviteMember = 'Invite a team member',
  addPayment = 'Add payment method',
  loadData = 'Load data',
  giveFeedback = 'Give feedback',
}

export async function getOnboardingTasksReq(): Promise<OnboardingTask[]> {
  const response = await axiosController.get('/v2/onboarding-tasks');
  return response.data.Result;
}
export async function completeOnboardingTaskReq(taskId: string): Promise<void> {
  const response = await axiosController.post('/v2/onboarding-tasks', { task_id: taskId });
  return response.data.Result;
}
