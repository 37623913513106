import { AxiosError } from 'axios';
import { useStyletron } from 'styletron-react';
import { StyledToast } from './styledToasterContainer';
import { KIND } from 'baseui/toast';
import { getErrorMessage } from '@/utils/utils';
import React from 'react';

export function ErrorDisplay({
  error,
  label,
  message,
  isGSQLError,
}: {
  error?: AxiosError | Error | null | undefined;
  label?: string;
  message?: React.ReactNode;
  isGSQLError?: boolean;
}) {
  const [css] = useStyletron();
  if (!(error || message)) {
    return null;
  }

  let displayMessage = message ?? '';
  let isInterpretGSQLError = false;

  if (error) {
    const errMessage = getErrorMessage(error);

    // 1. is gsql error
    // 2. detected if contain `interpreted mode`
    //      interpret query run will failed with different reason, only show interpreted query tips is contains `interpreted mode`
    isInterpretGSQLError = isGSQLError && errMessage.includes('interpreted mode') ? true : false;
    displayMessage = errMessage;
  }

  return (
    <div role="alert" className={css({ padding: '12px' })}>
      <StyledToast
        hideBorder={true}
        message={
          <div>
            {label ?? 'Something went wrong:'}
            <div
              className={css({
                marginTop: '8px',
                maxHeight: '320px',
                overflow: 'auto',
              })}
            >
              {displayMessage}
              {isInterpretGSQLError ? (
                <span>
                  GSQL queries run in interpreted mode have some{' '}
                  <a
                    href="https://docs.tigergraph.com/gsql-ref/current/appendix/interpreted-gsql-limitations"
                    rel="noreferrer"
                    target="_blank"
                  >
                    limitations
                  </a>
                  . To execute the query, please first save and install it in the Query Editor.
                </span>
              ) : null}
            </div>
          </div>
        }
        kind={KIND.negative}
        closeable={false}
      />
    </div>
  );
}
