import { CopyLinkIcon } from '@/pages/home/icons';
import { showToast } from '@tigergraph/app-ui-lib/styledToasterContainer';
import { useStyletron } from '@tigergraph/app-ui-lib/Theme';
import { useEffect } from 'react';
import useCopyClipboard from 'react-use-clipboard';

export default function CopyLink({ link }: { link: string }) {
  const [css, theme] = useStyletron();

  const [linkCopied, setLinkCopied] = useCopyClipboard(link, {
    successDuration: 1000,
  });

  useEffect(() => {
    if (linkCopied) {
      showToast({
        kind: 'positive',
        message: 'Link copied successfully.',
      });
    }
  }, [linkCopied]);

  return (
    <div
      className={css({
        color: theme.colors['text.link'],
        fontSize: '12px',
        fontWeight: 500,
        display: 'flex',
        alignItems: 'center',
        cursor: 'pointer',
        gap: '8px',
      })}
      onClick={setLinkCopied}
    >
      <CopyLinkIcon />
      Copy Link
    </div>
  );
}
