import { Result } from '@/lib/type';
import { getWorkSpaceDetail } from '@/pages/workgroup/api';
import { workSpacesROUpdateAtom } from '@/pages/workgroup/atom';
import { WorkGroupT, WorkspaceT, calculatePropsForWorkspace, isRefreshIng } from '@/pages/workgroup/type';
import { AxiosError } from 'axios';
import { useAtom } from 'jotai';
import { useQuery, useQueryClient } from 'react-query';
import { useWorkspaceContext } from '@/contexts/workspaceContext';
import { showToast } from '@tigergraph/app-ui-lib/styledToasterContainer';

const pollingInterval = 5 * 1000;

export function WorkspacesROUpdatePoll() {
  const [workspaces] = useAtom(workSpacesROUpdateAtom);

  return (
    <div>
      <div>
        {workspaces.map((workspace) => (
          <WorkspacePoll workspace={workspace} key={workspace.workspace_id} />
        ))}
      </div>
    </div>
  );
}

function WorkspacePoll({ workspace }: { workspace: WorkspaceT }) {
  const [, setWorkspaces] = useAtom(workSpacesROUpdateAtom);
  const queryClient = useQueryClient();
  const { groupsAccess } = useWorkspaceContext();

  useQuery<Result<WorkspaceT>, AxiosError>(
    ['space', workspace.workspace_id],
    async () => {
      return getWorkSpaceDetail(workspace.workgroup_id, workspace.workspace_id);
    },
    {
      initialData: {
        Error: false,
        Result: workspace,
      },
      onError: () => {
        // when error, remove it from the polling list
        setWorkspaces((oldData) => {
          return oldData.filter((item) => item.workspace_id !== workspace.workspace_id);
        });
      },
      onSuccess: (data) => {
        // update workgroup cache
        // for refresh, it will change the status from active -> pending -> active
        if (data?.Result) {
          const workspace = data?.Result;
          queryClient.setQueryData<Result<WorkGroupT> | undefined>(['group', workspace.workgroup_id], (oldData) => {
            if (!oldData?.Result) {
              return oldData;
            }
            const workgroup = oldData.Result;

            let newData = {
              ...oldData,
              Result: {
                ...oldData.Result,
                workspaces: oldData.Result.workspaces.map((w) =>
                  w.workspace_id !== workspace.workspace_id
                    ? w
                    : calculatePropsForWorkspace(workspace, workgroup, groupsAccess)
                ),
              },
            };
            return newData;
          });
        }

        // Polling until
        // the refresh status is not ing
        if (data?.Result && !isRefreshIng(data.Result.refresh_status)) {
          queryClient.invalidateQueries(['groups']);

          // remove the workspace from the polling list
          setWorkspaces((oldData) => {
            return oldData.filter((item) => item.workspace_id !== workspace.workspace_id);
          });

          if (
            (data.Result.refresh_status === 'Failed' || data.Result.refresh_status === 'Interrupted') &&
            data.Result.refresh_message
          ) {
            // show ro refresh failed notification
            showToast({
              kind: 'negative',
              message: data.Result.refresh_message,
              autoHideDuration: 0,
            });
          }
        }
      },
      refetchInterval: (data) => {
        if (!data?.Result) {
          setWorkspaces((oldData) => {
            return oldData.filter((item) => item.workspace_id !== workspace.workspace_id);
          });
          return false;
        }

        const workspace = data?.Result;
        let shouldFetch = false;
        if (isRefreshIng(workspace.refresh_status)) {
          shouldFetch = true;
        }

        if (!shouldFetch) {
          return false;
        }

        return pollingInterval;
      },
    }
  );

  return null;
}
