import { useStyletron } from '@tigergraph/app-ui-lib/Theme';
import { useOrgContext } from '@/contexts/orgContext';
import { HeadingMenuSelected } from '@tigergraph/app-ui-lib/typography';
import { ParagraphSmall, ParagraphXSmall } from 'baseui/typography';
import { Input } from '@tigergraph/app-ui-lib/input';
import { useEffect, useRef } from 'react';
import { Button } from '@tigergraph/app-ui-lib/button';
import { axiosController } from '@/lib/network';
import { useMutation } from 'react-query';
import { BaseSkeleton } from '@/components/BaseSkeleton';
import Logo from '@/pages/home/logo';
import invalidLogo from '@/pages/home/invalid-logo.svg';
import { showToast } from '@/components/styledToasterContainer';
import { getErrorMessage } from '@/utils/utils.ts';
import { AxiosError } from 'axios';
import { TableContainer } from '@/lib/styled.tsx';
import { useForm, Controller } from 'react-hook-form';
import { FormControl } from '@/components/FormControl';

const NameRegexp = /^[a-zA-Z0-9][a-z0-9_-]{2,49}$/;
const NameError =
  'invalid name, the name must only contain lowercase characters, ‘-’, and ‘_’, and start with a letter or number';
const NameRules = {
  required: 'required',
  pattern: {
    message: NameError,
    value: NameRegexp,
  },
};

type FormData = {
  orgName: string;
  displayName: string;
  logoUrl: string;
};

export default function OrgSettings() {
  const [css, theme] = useStyletron();
  const imgRef = useRef<HTMLImageElement>(null);
  const displayImgRef = useRef<HTMLImageElement>(null);
  const orgNameRef = useRef<HTMLDivElement>(null);
  const { currentOrg, setCurrentOrg } = useOrgContext();

  const {
    handleSubmit,
    formState: { errors },
    control,
    watch,
  } = useForm<FormData>({
    mode: 'onBlur',
    defaultValues: {
      orgName: currentOrg.org_name,
      displayName: currentOrg.org_display_name || '',
      logoUrl: currentOrg.logo_url || '',
    },
  });

  const imgUrl = watch('logoUrl');
  const displayName = watch('displayName');
  const orgName = watch('orgName');

  useEffect(() => {
    if (imgUrl !== '') {
      if (displayImgRef.current) {
        displayImgRef.current.style.visibility = 'visible';
        displayImgRef.current.style.height = '60px';
        displayImgRef.current.style.maxWidth = '100%';
      }
      if (orgNameRef.current) {
        orgNameRef.current.style.visibility = 'hidden';
      }
    } else {
      if (orgNameRef.current) {
        orgNameRef.current.style.visibility = 'visible';
      }
    }
  }, [imgUrl]);

  async function updateOrg({ orgName, displayName, logoUrl }: FormData) {
    const response = await axiosController.put(`/v2/org`, {
      orgName: orgName,
      orgDisplayName: displayName,
      logoUrl: logoUrl,
    });
    return response.data;
  }

  const updateOrgMutation = useMutation(updateOrg, {
    onSuccess: () => {
      showToast({
        kind: 'positive',
        message: 'Update organization successfully.',
      });
      setCurrentOrg({
        ...currentOrg,
        ...{
          org_name: orgName,
          display_name: displayName,
          logo_url: imgUrl,
        },
      });
    },
    onError: (error: AxiosError) => {
      showToast({
        kind: 'negative',
        message: getErrorMessage(error),
      });
    },
  });

  return (
    <form
      onSubmit={handleSubmit((data) => {
        updateOrgMutation.mutate(data);
      })}
    >
      <TableContainer $style={{ height: 'auto' }}>
        <div
          className={css({
            display: 'flex',
            flexDirection: 'column',
            gap: '24px',
          })}
        >
          <div
            className={css({
              display: 'flex',
              flexDirection: 'column',
              gap: '16px',
              maxWidth: '547px',
            })}
          >
            <div>
              <HeadingMenuSelected>Basic Information</HeadingMenuSelected>
              <ParagraphSmall>These are basic details needed to set up your new organization.</ParagraphSmall>
            </div>

            <div>
              <FormControl label="Organization Name" error={errors?.orgName?.message}>
                <Controller
                  rules={NameRules}
                  control={control}
                  name="orgName"
                  render={({ field }) => (
                    <Input
                      {...field}
                      error={!!errors?.orgName}
                      autoComplete="off"
                      overrides={{
                        Root: {
                          style: {
                            maxWidth: '100%',
                          },
                        },
                      }}
                    />
                  )}
                />
              </FormControl>
              <ParagraphXSmall
                className={css({
                  color: `${theme.colors['text.secondary']}`,
                  marginTop: '-8px',
                })}
              >
                This is a unique organization identifier on TigerGraph Savanna.
              </ParagraphXSmall>
            </div>

            <div>
              <FormControl label="Display Name" error={errors?.displayName?.message}>
                <Controller
                  rules={{
                    required: 'required',
                    minLength: {
                      value: 3,
                      message: 'minimum length is 3',
                    },
                    maxLength: {
                      value: 50,
                      message: 'maximum length is 50',
                    },
                  }}
                  control={control}
                  name="displayName"
                  render={({ field }) => (
                    <Input
                      {...field}
                      error={!!errors?.displayName}
                      autoComplete="off"
                      overrides={{
                        Root: {
                          style: {
                            maxWidth: '100%',
                          },
                        },
                      }}
                    />
                  )}
                />
              </FormControl>
              <ParagraphXSmall
                className={css({
                  color: `${theme.colors['text.secondary']}`,
                  marginTop: '-8px',
                })}
              >
                If set, this name will be displayed to users for your organization.
              </ParagraphXSmall>
            </div>
          </div>

          <div
            className={css({
              display: 'flex',
              flexDirection: 'column',
              gap: '16px',
            })}
          >
            <div>
              <HeadingMenuSelected>Branding</HeadingMenuSelected>
              <ParagraphSmall>These are branding settings associated with your organization.</ParagraphSmall>
            </div>

            <div
              className={css({
                display: 'flex',
              })}
            >
              <div
                className={css({
                  borderRight: `1px solid ${theme.colors.divider}`,
                  display: 'flex',
                  flexDirection: 'column',
                  gap: '8px',
                  paddingRight: '48px',
                })}
              >
                <FormControl label="Organization Logo Url">
                  {currentOrg.org_id ? (
                    <div
                      className={css({
                        borderRadius: '4px',
                        border: `1px solid ${theme.colors.divider}`,
                        background: `${theme.colors['background.alternative']}`,
                        height: '200px',
                        width: '397px',
                        position: 'relative',
                      })}
                    >
                      <div
                        className={css({
                          display: 'flex',
                          justifyContent: 'center',
                          position: 'absolute',
                          top: '40%',
                          left: '50%',
                          transform: 'translate(-50%, -50%)',
                        })}
                      >
                        <img
                          ref={imgRef}
                          onError={() => {
                            if (imgRef.current && imgUrl !== '') {
                              imgRef.current.src = invalidLogo;
                            }
                          }}
                          src={imgUrl}
                          className={css({
                            maxWidth: '100%',
                            height: '60px',
                            display: 'inline',
                          })}
                        />
                      </div>
                      <Controller
                        control={control}
                        name="logoUrl"
                        render={({ field }) => (
                          <Input
                            placeholder="Your logo URL"
                            {...field}
                            autoComplete="off"
                            value={imgUrl}
                            overrides={{
                              Root: {
                                style: {
                                  position: 'absolute',
                                  bottom: '0',
                                  borderBottomWidth: '0',
                                  borderLeftWidth: '0',
                                  borderRightWidth: '0',
                                  borderTopRightRadius: '0',
                                  borderTopLeftRadius: '0',
                                },
                              },
                            }}
                          />
                        )}
                      />
                    </div>
                  ) : (
                    <div>
                      <BaseSkeleton height="200px" width="397px" />
                    </div>
                  )}
                </FormControl>
              </div>
              <div
                className={css({
                  paddingLeft: '48px',
                  position: 'relative',
                  width: '397px',
                })}
              >
                <ParagraphXSmall>Display Logo</ParagraphXSmall>
                <div
                  className={css({
                    display: 'flex',
                    position: 'absolute',
                    top: '50%',
                    transform: 'translateY(-50%)',
                    alignItems: 'center',
                  })}
                >
                  {currentOrg.org_id ? (
                    <>
                      <Logo />
                      <div
                        className={css({
                          minWidth: '1px',
                          height: '40px',
                          background: `${theme.colors.divider}`,
                          margin: '0 8px',
                        })}
                      ></div>
                      {imgUrl && (
                        <img
                          ref={displayImgRef}
                          onError={() => {
                            if (displayImgRef.current) {
                              displayImgRef.current.style.visibility = 'hidden';
                              displayImgRef.current.style.height = '0px';
                              displayImgRef.current.style.maxWidth = '0px';
                            }
                            if (orgNameRef.current) {
                              orgNameRef.current.style.visibility = 'visible';
                            }
                          }}
                          src={imgUrl}
                          className={css({ maxWidth: '100%', height: '60px' })}
                        />
                      )}
                      <div
                        ref={orgNameRef}
                        className={css({
                          maxWidth: '120px',
                          textOverflow: 'ellipsis',
                          overflow: 'hidden',
                          whiteSpace: 'nowrap',
                          fontSize: '14px',
                          visibility: 'hidden',
                        })}
                      >
                        {displayName}
                      </div>
                    </>
                  ) : (
                    <BaseSkeleton height="60px" width="200px" />
                  )}
                </div>
              </div>
            </div>
            <ParagraphXSmall
              $style={{
                marginTop: '-16px',
              }}
            >
              If set, this logo will be what the users for your organization see and use to recognize the organization.
            </ParagraphXSmall>
          </div>

          <div>
            <Button
              size="large"
              type="submit"
              disabled={updateOrgMutation.isLoading}
              isLoading={updateOrgMutation.isLoading}
            >
              Update
            </Button>
          </div>
        </div>
      </TableContainer>
    </form>
  );
}
